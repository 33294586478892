<template>
  <div class="row">
    <div class="add-program-container card">
      <v-card color="basil">
        <v-card-title class="text-center justify-center py-6">
          <h1 class="font-weight-bold text-h2 basil--text">
            {{ copy ? copy.title_circuits : "" }}
          </h1>
          <button
            class="btn btn-primary ml-5"
            @click.prevent="allFinishAddCircuit"
          >
            Enregistrer
          </button>
        </v-card-title>

        <v-tabs v-model="tab" background-color="transparent" color="basil" grow>
          <v-tab> Programme journalier</v-tab>
          <v-tab>
            {{
              copy.is_garanted_departure != 0 ? "Date(s) de départ" : "Saisons"
            }}
          </v-tab>
          <v-tab> Prix</v-tab>
          <v-tab> Prestations</v-tab>
          <v-tab> Points forts</v-tab>
          <v-tab> Type de circuits et tags</v-tab>
          <v-tab>Galerie images</v-tab>
          <v-tab>Informations pratiques</v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
          <v-tab-item>
            <v-card color="basil" flat>
              <v-card-text>
                <div class="add-program-container card">
                  <section>
                    <div class="card-header">Programme journalier</div>

                    <div
                      class="card-body"
                      v-for="(item_day, day) in all_day"
                      :key="day"
                    >
                      <b-form :id="'form-program_' + day">
                        <div class="row">
                          <div class="list-program">
                            <div
                              class="day-title"
                              :id="'Jour' + plus_one_day(day)"
                            >
                              Jour {{ day * 1 + 1 * 1 }}
                            </div>

                            <div class="mt-8 card card-program">
                              <div class="card-header">Hébergement</div>

                              <div class="card-body">
                                <div class="row">
                                  <div class="col-md-6 form-group mb-0">
                                    <label for="cate_hote"
                                      >Nom de l'hébergement</label
                                    >
                                    <div class="px-0">
                                      <b-input
                                        v-model="item_day.nom_hebergement"
                                        class="input-refont bord-colr"
                                        required
                                      ></b-input>
                                    </div>
                                  </div>
                                  <div class="col-md-6 form-group mb-0">
                                    <label for="cate_hote"
                                      >Catégorie de l'hébergement</label
                                    >
                                    <div class="px-0">
                                      <b-form-select
                                        v-model="item_day.cat_hebergement"
                                        :options="stadings"
                                        class="form-control bord-colr"
                                        required
                                      ></b-form-select>
                                    </div>
                                  </div>
                                </div>

                                <div class="mt-2 row hebergement-image">
                                  <div class="col-md-6">
                                    <div
                                      class="col-md-12 px-0 alg-file-upload-form"
                                    >
                                      <div class="file-upload-form">
                                        <label
                                          @click="previewImage(day, 0)"
                                          :for="'upl-hote' + 0 + '_' + day"
                                        >
                                          <span>
                                            <i
                                              v-b-tooltip.hover
                                              title="Cliquez pour ajouter une photo"
                                              class="fa fa-camera"
                                            ></i>
                                          </span>
                                        </label>
                                        <div
                                          class="image-preview"
                                          :id="'previewHote' + 0 + '_' + day"
                                          style="
                                            width: 100% !important;
                                            height: 100% !important;
                                            object-fit: cover !important;
                                          "
                                        >
                                          <img
                                            data-v-4f7508cb
                                            v-if="!item_day.dataImage[0] == ''"
                                            class="preview"
                                            :src="item_day.dataImage[0]"
                                            style="
                                              width: 100% !important;
                                              height: 100% !important;
                                              object-fit: cover !important;
                                            "
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-md-6">
                                    <div
                                      class="col-md-12 px-0 alg-file-upload-form"
                                    >
                                      <div class="file-upload-form">
                                        <label
                                          @click="previewImage(day, 1)"
                                          :for="'upl-hote' + 1 + '_' + day"
                                        >
                                          <span>
                                            <i
                                              v-b-tooltip.hover
                                              title="Cliquez pour ajouter une photo"
                                              class="fa fa-camera"
                                            ></i>
                                          </span>
                                        </label>
                                        <div
                                          class="image-preview"
                                          :id="'previewHote' + 1 + '_' + day"
                                          style="
                                            width: 100% !important;
                                            height: 100% !important;
                                            object-fit: cover !important;
                                          "
                                        >
                                          <img
                                            data-v-4f7508cb
                                            v-if="!item_day.dataImage[1] == ''"
                                            class="preview"
                                            :src="item_day.dataImage[1]"
                                            style="
                                              width: 100% !important;
                                              height: 100% !important;
                                              object-fit: cover !important;
                                            "
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-md-6">
                                    <div
                                      class="col-md-12 px-0 alg-file-upload-form"
                                    >
                                      <div class="file-upload-form">
                                        <label
                                          @click="previewImage(day, 2)"
                                          :for="'upl-hote' + 2 + '_' + day"
                                        >
                                          <span>
                                            <i
                                              v-b-tooltip.hover
                                              title="Cliquez pour ajouter une photo"
                                              class="fa fa-camera"
                                            ></i>
                                          </span>
                                        </label>
                                        <div
                                          class="image-preview"
                                          :id="'previewHote' + 2 + '_' + day"
                                          style="
                                            width: 100% !important;
                                            height: 100% !important;
                                            object-fit: cover !important;
                                          "
                                        >
                                          <img
                                            v-if="!item_day.dataImage[2] == ''"
                                            class="preview"
                                            :src="item_day.dataImage[2]"
                                            style="
                                              width: 100% !important;
                                              height: 100% !important;
                                              object-fit: cover !important;
                                            "
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-md-6">
                                    <div
                                      class="col-md-12 px-0 alg-file-upload-form"
                                    >
                                      <div class="file-upload-form">
                                        <label
                                          @click="previewImage(day, 3)"
                                          :for="'upl-hote' + 3 + '_' + day"
                                        >
                                          <span>
                                            <i
                                              v-b-tooltip.hover
                                              title="Cliquez pour ajouter une photo"
                                              class="fa fa-camera"
                                            ></i>
                                          </span>
                                        </label>
                                        <div
                                          class="image-preview"
                                          :id="'previewHote' + 3 + '_' + day"
                                          style="
                                            width: 100% !important;
                                            height: 100% !important;
                                            object-fit: cover !important;
                                          "
                                        >
                                          <img
                                            v-if="!item_day.dataImage[3] == ''"
                                            class="preview"
                                            :src="item_day.dataImage[3]"
                                            style="
                                              width: 100% !important;
                                              height: 100% !important;
                                              object-fit: cover !important;
                                            "
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div class="mt-2" v-if="day === 0">
                                  <b-form-checkbox
                                    id="applicate-heberge-all-day"
                                    name="applicate-heberge-all-day"
                                    v-model="applique_tous_jour"
                                  >
                                    <span class="tout-color"
                                      >Appliquer pour tous les jours</span
                                    >
                                  </b-form-checkbox>
                                </div>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-6">
                                <div class="mt-8 card card-program">
                                  <div class="card-header">Pension</div>

                                  <div class="card-body">
                                    <div class="row">
                                      <div class="col-md-4">
                                        <b-form-checkbox
                                          :id="'pro-petit-dejeuner' + day"
                                          name="pro-petit-dejeuner"
                                          v-model="item_day.petit_dejeuner"
                                        >
                                          <span class="tout-color"
                                            >Petit déjeuner</span
                                          >
                                        </b-form-checkbox>
                                      </div>

                                      <div class="col-md-4 pl-6">
                                        <b-form-checkbox
                                          :id="'pro-dejeuner' + day"
                                          name="pro-dejeuner"
                                          v-model="item_day.dejeuner"
                                        >
                                          <span class="tout-color"
                                            >Déjeuner</span
                                          >
                                        </b-form-checkbox>
                                      </div>

                                      <div class="col-md-4 pl-6">
                                        <b-form-checkbox
                                          :id="'pro-diner' + day"
                                          name="pro-diner"
                                          v-model="item_day.dinner"
                                        >
                                          <span class="tout-color">Dîner</span>
                                        </b-form-checkbox>
                                      </div>
                                    </div>
                                    <div class="mt-2" v-if="day === 0">
                                      <b-form-checkbox
                                        id="applicate-pension-all-day"
                                        name="applicate-pension-all-day"
                                        v-model="applique_tous_jour_pension"
                                      >
                                        <span class="tout-color"
                                          >Appliquer pour tous les jours</span
                                        >
                                      </b-form-checkbox>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div class="col-6">
                                <div class="mt-8 card card-program">
                                  <div class="card-header">
                                    Guide et chauffeur
                                  </div>

                                  <div class="card-body">
                                    <div class="row">
                                      <div class="col-md-4">
                                        <b-form-checkbox
                                          :id="'pro-guide' + day"
                                          name="pro-guide"
                                          v-model="item_day.guide"
                                        >
                                          <span class="tout-color">Guide</span>
                                        </b-form-checkbox>
                                      </div>

                                      <div class="col-md-4 pl-6">
                                        <b-form-checkbox
                                          :id="'pro-chauffeur' + day"
                                          name="pro-chauffeur"
                                          v-model="item_day.chauffeur"
                                        >
                                          <span class="tout-color"
                                            >Chauffeur</span
                                          >
                                        </b-form-checkbox>
                                      </div>
                                    </div>
                                    <div class="mt-2" v-if="day == 0">
                                      <b-form-checkbox
                                        id="applicate-guide-all-day"
                                        name="applicate-guide-all-day"
                                        v-model="
                                          applique_tous_jour_guide_chauffeur
                                        "
                                      >
                                        <span class="tout-color"
                                          >Appliquer pour tous les jours</span
                                        >
                                      </b-form-checkbox>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="av-pad">
                            <div class="mt-8 my-3 px-0 card card-program">
                              <div class="card-header ex1">Activités</div>

                              <div class="card-header">
                                <b-form-group class="label-form">
                                  <vue-select
                                    v-model="item_day.activiter"
                                    class="form-control-select multiple-select"
                                    :options="activites"
                                    placeholder="Sélectionnez ou tapez les activités"
                                    label="name_activite"
                                    track-by="id_activite"
                                    :multiple="true"
                                    :taggable="true"
                                  >
                                    <template v-slot:open-indicator>
                                      <i class="fas fa-sort-down"></i>
                                    </template>
                                  </vue-select>
                                </b-form-group>
                              </div>
                            </div>

                            <div
                              class="mt-8 px-0 card card-program list-parcours"
                              v-for="(parcour, itn) in item_day.parcour"
                              :key="itn"
                            >
                              <div class="card-header">
                                Parcours {{ itn * 1 + 1 * 1 }}
                              </div>

                              <div class="card-body">
                                <div class="row mt-0">
                                  <div class="col-md-12">
                                    <b-form-group
                                      id="label-titre-parcourt"
                                      label="Titre itinéraire"
                                      label-for="titre-parcours"
                                      class="label-form tout-color"
                                    >
                                      <b-form-input
                                        v-model="parcour.title_itineraire"
                                        autocomplete="off"
                                        id="titre-parcours"
                                        required
                                        type="text"
                                        class="input-add-circuit"
                                      ></b-form-input>
                                    </b-form-group>
                                  </div>
                                </div>

                                <div class="row mt-0">
                                  <div class="col-md-6">
                                    <b-form-group
                                      id="label-depart-parcourt"
                                      label="Lieu de départ"
                                      label-for="depart-parcours"
                                      class="label-form tout-color"
                                    >
                                      <vue-google-autocomplete
                                        :ref="'address_' + day + '_' + itn"
                                        :id="'map_' + day + '_' + itn"
                                        v-model="parcour.departure_city"
                                        classname="form-control"
                                        placeholder="Lieu de depart"
                                        v-on:placechanged="getAddressData"
                                        class="input-add-circuit"
                                        types="geocode"
                                        @change="
                                          change_depart(
                                            'map_' + day + '_' + itn
                                          )
                                        "
                                      >
                                      </vue-google-autocomplete>
                                      <input
                                        ref="hidden"
                                        type="hidden"
                                        :value="[day, itn]"
                                        :id="'map_' + day + '_' + itn"
                                      />
                                    </b-form-group>
                                  </div>

                                  <div class="col-md-6">
                                    <b-form-group
                                      id="label-arrive-parcourt"
                                      label="Lieu d'arrivée"
                                      label-for="arrivee-parcours"
                                      class="label-form tout-color"
                                    >
                                      <vue-google-autocomplete
                                        :ref="
                                          'address_' + day + '_' + itn + '_' + 1
                                        "
                                        :id="'map_' + day + '_' + itn + '_' + 1"
                                        v-on:placechanged="getAddress2Data"
                                        v-model="parcour.arrival_city"
                                        classname="form-control"
                                        placeholder="Lieu d'arrivée"
                                        class="input-add-circuit"
                                        types="geocode"
                                        @change="
                                          change_arrival(
                                            'map_' + day + '_' + itn + '_' + 1
                                          )
                                        "
                                      >
                                      </vue-google-autocomplete>
                                      <input
                                        type="hidden"
                                        :value="[day, itn]"
                                        :id="'map_' + day + '_' + itn + '_' + 1"
                                      />
                                    </b-form-group>
                                  </div>
                                </div>

                                <div class="row mt-0">
                                  <div class="col-md-6">
                                    <b-form-group
                                      id="label-length-parcourt"
                                      label="Longueur du trajet"
                                      label-for="length-parcours"
                                      class="label-form tout-color"
                                    >
                                      <b-form-input
                                        required
                                        v-model="parcour.path_length"
                                        autocomplete="off"
                                        id="length-parcours"
                                        type="number"
                                        min="0"
                                        class="input-add-circuit"
                                        placeholder="km"
                                      ></b-form-input>
                                    </b-form-group>
                                  </div>

                                  <div class="col-md-6">
                                    <b-form-group
                                      id="label-duration-parcourt"
                                      label="Durée du trajet"
                                      label-for="duration-parcours"
                                      class="label-form tout-color"
                                    >
                                      <b-form-input
                                        required
                                        v-model="parcour.travel_time"
                                        id="duration-parcours"
                                        type="number"
                                        min="0"
                                        class="input-add-circuit"
                                        placeholder="mn"
                                      ></b-form-input>
                                    </b-form-group>
                                  </div>
                                </div>

                                <div
                                  class="mt-8 mt-3 card card-program list-parcours"
                                  v-for="(visite, vs) in parcour.visites"
                                  :key="vs"
                                >
                                  <div class="card-header edz">
                                    Lieu de visite
                                    {{ vs * 1 + 1 * 1 }}
                                    (&nbsp; dans parcours
                                    {{ itn * 1 + 1 * 1 }}&nbsp;)
                                    <div class="er">
                                      <i
                                        class="fa fa-trash"
                                        @click="onRemoveVisite(day, itn, vs)"
                                        v-show="
                                          vs ||
                                          (!vs && parcour.visites.length > 1)
                                        "
                                      ></i>
                                      <i
                                        class="fa fa-plus"
                                        @click="onAddVisite(day, itn)"
                                        v-show="
                                          parcour.visites.length - 1 == vs
                                        "
                                      ></i>
                                    </div>
                                  </div>

                                  <div class="card-body">
                                    <div>
                                      <div class="file-upload-form1 un">
                                        <label
                                          @click="
                                            previewImageParcour(day, itn, vs)
                                          "
                                          :for="
                                            'upl_vgt_visit' +
                                            vs +
                                            '_' +
                                            itn +
                                            '_' +
                                            day
                                          "
                                        >
                                          <span>
                                            <i
                                              v-b-tooltip.hover
                                              title="Cliquez pour ajouter une photo"
                                              class="fa fa-camera"
                                            ></i>
                                          </span>
                                        </label>

                                        <div
                                          class="image-preview"
                                          :id="
                                            'preview-visit' +
                                            vs +
                                            '_' +
                                            itn +
                                            '_' +
                                            day
                                          "
                                          style="
                                            width: 100% !important;
                                            height: 100% !important;
                                            object-fit: cover !important;
                                          "
                                        >
                                          <img
                                            v-if="visite.visite_cover"
                                            class="preview1"
                                            :src="
                                              imgPathVisite +
                                              visite.visite_cover
                                            "
                                            style="
                                              width: 100% !important;
                                              height: 100% !important;
                                              object-fit: cover !important;
                                            "
                                          />
                                        </div>
                                      </div>
                                    </div>

                                    <div class="mt-2">
                                      <b-form-group
                                        id="label-description-parcourt"
                                        label="Description de la visite"
                                        label-for="description-parcours"
                                        class="label-form tout-color"
                                      >
                                        <b-form-textarea
                                          v-model="visite.description_visite"
                                          id="description-parcours"
                                          required
                                          placeholder="Entrez la description..."
                                          class="input-add-circuit"
                                        ></b-form-textarea>
                                      </b-form-group>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div class="card-footer">
                                <div class="add-container">
                                  <div class="head-add flex">
                                    <!-- <div
                                                                                                          type="button"
                                                                                                          class="btn icon-add"
                                                                                                          @click="addItine(day)"
                                                                                                          v-show="itn == item_day.parcour.length - 1"
                                                                                                      ></div> -->
                                    <button
                                      type="button"
                                      class="btn add-button padding-bnt-crs"
                                      @click="addItine(day)"
                                      v-show="
                                        itn == item_day.parcour.length - 1
                                      "
                                    >
                                      Ajouter un parcours
                                    </button>
                                    <button
                                      type="button"
                                      class="btn del-moment"
                                      @click="removeItine(day, itn)"
                                      v-show="item_day.parcour.length > 1"
                                    >
                                      <span>
                                        <i class="fa fa-trash"></i>
                                        Supprimer ce parcours
                                      </span>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="mt-4 add-jour-container">
                          <div
                            class="head-add icon-add-jour"
                            v-show="day == all_day.length - 1"
                          >
                            <!-- <div class="icon-add">+</div> -->
                            <button
                              class="btn add-button padding-bnt-crs add"
                              @click="addDay(day)"
                            >
                              Ajouter un jour
                            </button>
                          </div>
                          <button
                            class="btn add-button padding-bnt-crs supp"
                            @click.prevent="removeDay(day)"
                          >
                            <span
                              class="fa fa-trash icon-add-tranh d-flex"
                            ></span>
                            Supprimer un jour
                          </button>

                          <button
                            @click.prevent="updateOrSaveDay(day)"
                            class="btn add-button padding-bnt-crs save"
                          >
                            <span :id="'save_day_' + day">
                              {{ item_day.msgBtnEnregistrement }}
                            </span>
                            <div
                              class="spinner-border"
                              role="status"
                              v-if="item_day.loading"
                            >
                              <span class="sr-only">Loading...</span>
                            </div>
                          </button>
                        </div>
                      </b-form>
                    </div>
                  </section>

                  <section>
                    <image-compressor
                      ref="compressor"
                      :done="getFiles"
                      :scale="scale"
                      :quality="quality"
                      class="none"
                    >
                    </image-compressor>
                  </section>
                  <section>
                    <image-compressor
                      ref="compressorParcour"
                      :done="getFilesParcour"
                      class="none"
                    >
                    </image-compressor>
                  </section>
                </div>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card color="basil" flat>
              <v-card-text>
                <div class="add-circuit-saison-container card">
                  <div class="card-header">
                    {{
                      copy.is_garanted_departure != 0
                        ? "Date(s) de départ"
                        : "Saisons"
                    }}
                  </div>

                  <div class="card-body">
                    <div class="cnt-alg-insert-point">
                      <form
                        v-if="guarted_departure"
                        action
                        id="form-saison"
                        @submit.prevent="saveOrUpdateSaison()"
                      >
                        <div class="row">
                          <v-tabs class="garantieTabs" v-model="tabg">
                            <v-tab v-for="(garantie, d) in garanties" :key="d"
                              >Date {{ d + 1 }}
                              <v-btn
                                @click="deleteGaraties(d)"
                                elevation="2"
                                icon
                              >
                                <v-icon>mdi-close-circle</v-icon>
                              </v-btn>
                            </v-tab>
                            <div class="btn_plus_saison">
                              <button type="button" @click="onAddGaraties()">
                                <span class="v-btn__content">
                                  <i class="fa fa-plus"></i>
                                </span>
                              </button>
                            </div>
                          </v-tabs>
                          <v-tabs-items v-model="tabg" class="contentTabs mt-3">
                            <v-tab-item
                              v-for="(garantie, g) in garanties"
                              :key="g"
                            >
                              <v-card flat>
                                <div class="row">
                                  <v-col
                                    class="withMaxwidth withPadding"
                                    cols="12"
                                    xl="6"
                                    lg="12"
                                    md="12"
                                    sm="6"
                                    xs="12"
                                  >
                                    <v-radio-group
                                      v-model="garantie.option"
                                      row
                                    >
                                      <v-radio
                                        @change="getOption('date', g)"
                                        label="Date"
                                        color="red"
                                        value="date"
                                      ></v-radio>
                                      <v-radio
                                        label="Jours"
                                        color="red darken-3"
                                        value="jours"
                                        @change="getOption('jour', g)"
                                      ></v-radio>
                                    </v-radio-group>

                                    <b-form-group
                                      class="label-form"
                                      :id="'selecte' + g"
                                      v-if="garantie.isGetDay"
                                    >
                                      <vue-select
                                        class="form-control-select saison multiple-select activite mac-wid"
                                        :options="weeks"
                                        v-model="garantie.day"
                                        @input="getWeekOrMonth(g)"
                                        placeholder="Sélectionnez ou tapez un jour"
                                        label="name_activite"
                                        track-by="id_activite"
                                        :multiple="true"
                                        :taggable="true"
                                      >
                                        <template v-slot:open-indicator>
                                          <i
                                            data-v-4f7508cb=""
                                            aria-hidden="true"
                                            class="v-icon notranslate mdi mdi-menu-down theme--light"
                                          ></i>
                                          <!-- <i class="fas fa-sort-down"></i> -->
                                        </template>
                                      </vue-select>

                                      <vue-select
                                        class="form-control-select saison multiple-select activite mac-wid my-2"
                                        :options="months"
                                        v-model="garantie.monthSelected"
                                        @input="getWeekOrMonth(g)"
                                        placeholder="Sélectionnez ou tapez un mois"
                                        label="name"
                                        track-by="id_activite"
                                        :multiple="true"
                                        :taggable="true"
                                        :disables="garantie.isAllmonth"
                                      >
                                        <template v-slot:open-indicator>
                                          <!-- <i class="fas fa-sort-down"></i> -->
                                          <i
                                            data-v-4f7508cb=""
                                            aria-hidden="true"
                                            class="v-icon notranslate mdi mdi-menu-down theme--light"
                                          ></i>
                                        </template>
                                      </vue-select>
                                    </b-form-group>
                                    <v-card
                                      class="mx-auto"
                                      max-width="500"
                                      :id="'card' + g"
                                      v-if="garantie.open"
                                    >
                                      <v-list shaped>
                                        <v-list-item-group>
                                          <template v-for="(item, i) in items">
                                            <v-list-item
                                              :value="i"
                                              active-class="deep-purple--text text--accent-4"
                                            >
                                              <template>
                                                <v-list-item-content>
                                                  <v-list-item-title
                                                    v-text="item"
                                                  ></v-list-item-title>
                                                </v-list-item-content>

                                                <v-list-item-action>
                                                  <v-checkbox
                                                    :key="`item-${i}`"
                                                    :disabled="
                                                      garantie.all_month_selected &&
                                                      i == 0
                                                    "
                                                    v-model="garantie.choix"
                                                    @change="
                                                      getCondition($event, g)
                                                    "
                                                    :value="item"
                                                    color="deep-purple accent-4"
                                                  ></v-checkbox>
                                                </v-list-item-action>
                                              </template>
                                            </v-list-item>
                                          </template>
                                        </v-list-item-group>
                                      </v-list>
                                    </v-card>
                                  </v-col>
                                  <div
                                    class="col-xl-6 col-lg-12 col-md-12 col-sm-6 col-xs-12 px4"
                                  >
                                    <v-date-picker
                                      :disabled="garantie.open ? false : true"
                                      locale="fr"
                                      :color="'#f26923'"
                                      v-model="garantie.dates"
                                      :allowed-dates="generateAllowedDates"
                                      @input="onChangeDate(g)"
                                      :show-current="
                                        garantie.dates.length > 0
                                          ? garantie.dates[0]
                                          : gateNowDate()
                                      "
                                      selectedItemsText="{0} sélectionné"
                                      multiple
                                      full-width
                                    ></v-date-picker>
                                  </div>
                                </div>
                              </v-card>
                            </v-tab-item>
                          </v-tabs-items>

                          <div class="col-md-12 alg-cnt-sub pt-0">
                            <div
                              class="cnt-link-tour w-100 text-right cnt-btn-tour alg-cnt-sub text-center"
                            >
                              <button
                                type="submit"
                                class="btn add-button padding-bnt-crs save"
                              >
                                {{ button_name_saison }}
                                <pulse-loader
                                  :loading="loading_saison"
                                  :color="color"
                                ></pulse-loader>
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>

                      <form
                        v-else
                        action
                        id="form-saison"
                        @submit.prevent="saveOrUpdateSaison()"
                      >
                        <div class="content_saison">
                          <div
                            class="content_trance_saison"
                            v-for="(saison, s) in saison_date"
                            v-bind:key="s"
                          >
                            <VueCtkDateTimePicker
                              :id="s"
                              v-model="saison.value"
                              :range="demo.options.range"
                              :format="demo.options.format"
                              :formatted="demo.options.formatted"
                              :color="demo.options.color"
                              :button-color="demo.options.buttonColor"
                              :label="demo.options.label"
                              :no-shortcuts="demo.options.noShortcuts"
                              :no-button="demo.options.noButton"
                              :no-button-now="demo.options.noButtonNow"
                              :disabled-dates="disabled_dates"
                            >
                              <button
                                type="button"
                                class="lm-btn"
                                style="margin: 0"
                              />
                            </VueCtkDateTimePicker>

                            <i
                              class="fa fa-minus"
                              @click="delete_saison(s)"
                            ></i>
                          </div>

                          <div class="btn_plus_saison">
                            <button type="button" @click="add_saison()">
                              <span class="v-btn__content">
                                <i class="fa fa-plus"></i>
                              </span>
                            </button>
                          </div>
                        </div>

                        <div class="col-md-12 alg-cnt-sub text-center">
                          <div class="btn add-button padding-bnt-crs save">
                            <button
                              type="submit"
                              class="btn add-button padding-bnt-crs save"
                            >
                              {{ button_name_saison }}
                              <pulse-loader
                                :loading="loading_saison"
                                :color="color"
                              ></pulse-loader>
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card color="basil" flat>
              <v-card-text>
                <!-- Price update  -->
                <div class="add-price-container card">
                  <div class="card-header" v-if="guarted_departure == false">
                    Prix par catégorie d'hébergement
                  </div>

                  <v-tabs
                    v-model="tabSaison"
                    background-color="transparent"
                    color="basil"
                    grow
                  >
                    <v-tab
                      v-for="(saison, index) in saison_price_data"
                      :key="index"
                      @change="checkTrancheValide(index)"
                    >
                      {{ saison.title }}
                    </v-tab>
                    <v-btn text @click="newsaison()">
                      <i class="fa fa-plus"></i>
                    </v-btn>
                  </v-tabs>
                  <v-tabs-items v-model="tabSaison">
                    <v-tab-item
                      v-for="(saison, index) in saison_price_data"
                      :key="index"
                    >
                      <v-btn text @click="deletePriceSaison(index)"
                        >Supprimer tabs</v-btn
                      >
                      <div class="card-body">
                        <!-- card voyageur -->
                        <b-input type="text" v-model="saison.title"></b-input>
                        <b-form>
                          <div
                            class="mt-2 card card-traveler card-border"
                            v-if="guarted_departure == false"
                          >
                            <div class="card-header">Nombre de voyageur</div>

                            <div class="card-body">
                              <b-form-group>
                                <div class="row">
                                  <div
                                    class="col-md-3 tec-center"
                                    v-if="guarted_departure"
                                  >
                                    <!-- <b-form-radio disabled name="traveler-amount" v-model="saison.tranche"
                                                                    :value="'01-01'" @change="onChangeVoyage(0,0)">01-02
                                                                  </b-form-radio> -->
                                  </div>
                                  <div
                                    v-else
                                    class="col-md-3 tec-center"
                                    v-for="(voyageur, vg) in nb_voyageurs"
                                    :key="vg"
                                  >
                                    <!-- {{ saison.tranche }} -->
                                    <b-form-radio
                                      :name="'traveler-amount' + index"
                                      v-model="saison.tranche"
                                      :value="vg"
                                      @change="onChangeVoyage(index, vg)"
                                      >{{ filterNbVoyage(voyageur) }}
                                    </b-form-radio>
                                    <div
                                      class="col-md-3 add-traveler-option option_edit"
                                      v-if="vg > 9 && nb_voyageursValid[vg]"
                                    >
                                      <i
                                        class="fa fa-edit"
                                        @click="onEditNbVoyage(index, vg)"
                                      ></i>
                                    </div>
                                    <div
                                      class="col-md-3 add-traveler-option aption_delete"
                                      v-if="(vg > 9 || all_nb_voyage[index][vg]) && nb_voyageursValid[vg]"
                                    >
                                      <i
                                        class="fa fa-trash"
                                        @click="deleteNbVoyage(index, vg)"
                                      ></i>
                                    </div>
                                  </div>
                                  <div
                                    class="col-md-6 input_nb_voyages"
                                    v-if="
                                      saison.input_val_tranch &&
                                      guarted_departure == false
                                    "
                                  >
                                    <input
                                      class="form-price-edit"
                                      type="number"
                                      name="nb_min"
                                      v-model="saison.nb_min"
                                    />
                                    à
                                    <input
                                      class="form-price-edit"
                                      type="number"
                                      name="nb_max"
                                      v-model="saison.nb_max"
                                    />
                                    <button
                                      type="button"
                                      @click="
                                        addTranchNbVoyage(
                                          index,
                                          saison.nb_min,
                                          saison.nb_max
                                        )
                                      "
                                      class="btn btn-primary"
                                    >
                                      <i class="fa fa-check"></i>
                                    </button>
                                    <button
                                      type="button"
                                      @click="deleteTranchNbVoyage(index)"
                                      class="btn btn-secondary"
                                    >
                                      <i class="fa fa-times"></i>
                                    </button>
                                  </div>
                                  <div class="cal-md-3 input_nb_voyages">
                                    <button
                                      type="button"
                                      v-on:click="
                                        saison.input_val_tranch = true
                                      "
                                      class="btn btn-warning"
                                    >
                                      <i class="fa fa-plus"></i>
                                    </button>
                                  </div>
                                </div>
                              </b-form-group>
                            </div>
                          </div>

                          <div
                            v-if="saison_data.length > 0"
                            class="mt-4 card card-price-categorie card-border"
                          >
                            <div class="card-header">
                              Rattaché avec à un saison
                            </div>
                            <div class="card-body">
                              <div class="row">
                                <div class="col-md-12">
                                  <vue-select
                                    ref="select"
                                    v-model="saison.saison_id"
                                    :options="saison_data"
                                    :value="saison_data.id"
                                    label="label"
                                    :multiple="true"
                                    placeholder="Sélectionnez une saison"
                                  >
                                  </vue-select>
                                </div>
                              </div>
                            </div>
                          </div>

                          <!-- card price simple -->
                          <div
                            v-if="
                              is_mixed_price === false &&
                              guarted_departure === false
                            "
                            class="mt-4 card card-price-categorie card-border"
                          >
                            <div class="card-header">
                              {{
                                guarted_departure
                                  ? "Ajoute le prix"
                                  : "Prix par catégorie simple"
                              }}
                            </div>

                            <div
                              class="card-body"
                              v-if="guarted_departure == false"
                            >
                              <div class="row">
                                <div class="col-md-4">
                                  <b-form-group
                                    id="label-habitant-price"
                                    label="Chez l'habitant"
                                    label-for="habitant-price"
                                    class="label-form"
                                  >
                                    <b-form-input
                                      id="habitant-price"
                                      type="number"
                                      min="0"
                                      class="input-add-circuit"
                                      placeholder="€ 0.00"
                                      :keyup="shangeStateSave(price_etoiles)"
                                      v-model="saison.price_etoiles.price"
                                    ></b-form-input>
                                  </b-form-group>
                                </div>
                                <div class="col-md-4">
                                  <b-form-group
                                    id="label-two-price"
                                    label="2 étoiles"
                                    label-for="two-price"
                                    class="label-form"
                                  >
                                    <b-form-input
                                      id="two-price"
                                      type="number"
                                      min="0"
                                      class="input-add-circuit"
                                      placeholder="€ 0.00"
                                      :keyup="shangeStateSave(price_etoiles)"
                                      v-model="
                                        saison.price_etoiles.deux_etoiles
                                      "
                                    ></b-form-input>
                                  </b-form-group>
                                </div>
                                <div class="col-md-4">
                                  <b-form-group
                                    id="label-three-price"
                                    label="3 étoiles"
                                    label-for="three-price"
                                    class="label-form"
                                  >
                                    <b-form-input
                                      id="three-price"
                                      type="number"
                                      class="input-add-circuit"
                                      placeholder="€ 0.00"
                                      :keyup="shangeStateSave(price_etoiles)"
                                      v-model="
                                        saison.price_etoiles.trois_etoiles
                                      "
                                    ></b-form-input>
                                  </b-form-group>
                                </div>
                              </div>

                              <div class="row">
                                <div class="col-md-6">
                                  <b-form-group
                                    id="label-four-price"
                                    label="4 étoiles"
                                    label-for="four-price"
                                    class="label-form"
                                  >
                                    <b-form-input
                                      id="four-price"
                                      type="number"
                                      class="input-add-circuit"
                                      placeholder="€ 0.00"
                                      :keyup="shangeStateSave(price_etoiles)"
                                      v-model="
                                        saison.price_etoiles.quatres_etoiles
                                      "
                                    ></b-form-input>
                                  </b-form-group>
                                </div>
                                <div class="col-md-6">
                                  <b-form-group
                                    id="label-five-price"
                                    label="5 étoiles"
                                    label-for="five-price"
                                    class="label-form"
                                  >
                                    <b-form-input
                                      id="five-price"
                                      type="number"
                                      min="0"
                                      class="input-add-circuit"
                                      placeholder="€ 0.00"
                                      :keyup="shangeStateSave(price_etoiles)"
                                      v-model="
                                        saison.price_etoiles.cinq_etoiles
                                      "
                                    ></b-form-input>
                                  </b-form-group>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div
                            v-show="guarted_departure"
                            class="mt-4 card card-price-categorie card-border"
                          >
                            <div class="card-header">Ajouter un prix</div>
                            <div class="card-body">
                              <div class="row">
                                <div class="col-md-12">
                                  <b-form-group
                                    id="label-garantie-price"
                                    label="Prix départ garanti"
                                    label-for="garantie-price"
                                    class="label-form"
                                  >
                                    <b-form-input
                                      id="garantie-price"
                                      type="number"
                                      min="0"
                                      class="input-add-circuit"
                                      placeholder="€ 0.00"
                                      v-model="saison.garantie_prix"
                                    ></b-form-input>
                                  </b-form-group>
                                </div>
                              </div>
                            </div>
                          </div>

                          <!-- card mixte price -->
                          <div
                            v-if="is_mixed_price && guarted_departure == false"
                            class="mt-4 card card-price-categorie card-border"
                          >
                            <div class="card-header">
                              Prix par catégorie mixte
                            </div>

                            <div class="card-body">
                              <div class="row">
                                <div class="col-md-4">
                                  <b-form-group
                                    id="label-habitant-price"
                                    label="Categorie 1"
                                    label-for="habitant-price"
                                    class="label-form"
                                  >
                                    <b-form-select
                                      class="form-control"
                                      name="text"
                                      v-model="saison.price_etoiles.mixte_cat1"
                                      :options="stadings"
                                    >
                                      <template v-slot:open-indicator>
                                        <div class="select-button">
                                          <i class="fas fa-chevron-up"></i>
                                          <i class="fas fa-chevron-down"></i>
                                        </div>
                                      </template>
                                    </b-form-select>
                                  </b-form-group>
                                </div>
                                <div class="col-md-4">
                                  <b-form-group
                                    id="label-two-price"
                                    label="Categorie 2"
                                    label-for="two-price"
                                    class="label-form"
                                  >
                                    <b-form-select
                                      v-model="saison.price_etoiles.mixte_cat2"
                                      class="form-control-select"
                                      name="text"
                                      :options="stadings"
                                    >
                                      <template v-slot:open-indicator>
                                        <div class="select-button">
                                          <i class="fas fa-chevron-up"></i>
                                          <i class="fas fa-chevron-down"></i>
                                        </div>
                                      </template>
                                    </b-form-select>
                                  </b-form-group>
                                </div>
                                <div class="col-md-4">
                                  <b-form-group
                                    id="label-three-price"
                                    label="Categorie 3"
                                    label-for="three-price"
                                    class="label-form"
                                  >
                                    <b-form-select
                                      v-model="saison.price_etoiles.mixte_cat3"
                                      :options="stadings"
                                      class="form-control-select"
                                      name="text"
                                    >
                                      <template v-slot:open-indicator>
                                        <div class="select-button">
                                          <i class="fas fa-chevron-up"></i>
                                          <i class="fas fa-chevron-down"></i>
                                        </div>
                                      </template>
                                    </b-form-select>
                                  </b-form-group>
                                </div>
                              </div>

                              <div class="">
                                <b-form-group
                                  id="label-five-price"
                                  label="Prix mixte"
                                  label-for="five-price"
                                  class="label-form"
                                >
                                  <b-form-input
                                    placeholder="€ 0.00"
                                    :keyup="shangeStateSave(price_etoiles)"
                                    id="five-price"
                                    type="text"
                                    class="input-add-circuit"
                                    v-model="saison.price_etoiles.mixte_price"
                                  ></b-form-input>
                                </b-form-group>
                              </div>
                            </div>
                          </div>

                          <div class="mt-4 save-container text-center">
                            <button
                              @click.prevent="onUpdateOrSavePriceSaison()"
                              type="submit"
                              :disabled="$v.saison_price_data.$invalid"
                              class="btn add-button padding-bnt-crs save"
                            >
                              {{ button_name_price }}
                              <pulse-loader
                                :loading="loading_price"
                                :color="color_price"
                              ></pulse-loader>
                            </button>
                          </div>
                        </b-form>
                      </div>
                    </v-tab-item>
                  </v-tabs-items>
                </div>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card color="basil" flat>
              <v-card-text>
                <div class="add-prestation-container card">
                  <div class="card-header">Prestations</div>

                  <div class="card-body">
                    <b-form @submit.prevent="updateOrSavePrestation">
                      <!-- card incluse -->
                      <b-form-group
                        id="label-best-point"
                        class="label-form mt-4 m5"
                      >
                        <b-input-group
                          class="mb-2"
                          v-for="(input, k) in inputs"
                          :key="k"
                        >
                          <template #append>
                            <b-input-group-text class="exea">
                              <button
                                @click.prevent="add(k)"
                                class="type-number-container"
                              >
                                +
                              </button>
                              <button
                                v-if="inputs.length != 1"
                                @click.prevent="remove(k)"
                                class="type-number-container"
                              >
                                -
                              </button>
                            </b-input-group-text>
                          </template>
                          <b-form-input
                            id="best-point-circuit"
                            type="text"
                            class="input-add-circuit"
                            placeholder="Description de la prestation"
                            v-model="description_prestations[k]"
                          ></b-form-input>
                        </b-input-group>
                      </b-form-group>
                      <div class="row">
                        <div class="col-6">
                          <div
                            class="mt-2 card card-prestation-include card-border"
                          >
                            <div class="card-header">
                              Prestations
                              <span class="stronger">incluses</span> dans le
                              prix
                            </div>

                            <div class="card-body newscroll">
                              <div class="columns-checkbox">
                                <b-form-group class="position-checkbox-nav">
                                  <div class="row text-checkbox-col-6 ml-0">
                                    <div
                                      class="col-md-12 p-0"
                                      v-for="(
                                        options, index
                                      ) in option_prestations_incluse"
                                      :key="index"
                                    >
                                      <v-checkbox
                                        v-model="model_prestations_incluse"
                                        :label="options"
                                        :value="options"
                                        :id="options"
                                        class="text-checkbox"
                                      >
                                      </v-checkbox>
                                    </div>
                                  </div>
                                </b-form-group>
                              </div>
                              <div class="add-nexw">
                                <b-form-group
                                  id="label-prestation"
                                  class="label-form mt-4 tout-color"
                                >
                                  <b-form-input
                                    id="prestation-circuit"
                                    type="text"
                                    class="input-add-circuit"
                                    v-model="prestation_value"
                                  ></b-form-input>
                                </b-form-group>

                                <div class="add-container">
                                  <div class="head-add">
                                    <button
                                      @click.prevent="add_prestation_value"
                                      class="add-button1"
                                    >
                                      Ajouter
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <!-- card non incluse -->
                        <div class="col-6">
                          <div
                            class="mt-2 card card-prestation-include card-border"
                          >
                            <div class="card-header">
                              Prestations
                              <span class="stronger">non incluses</span> dans le
                              prix
                            </div>

                            <div class="card-body newscroll">
                              <b-form-group class="columns-checkbox">
                                <v-checkbox
                                  v-for="(
                                    options, index
                                  ) in option_prestations_non_inclse"
                                  :key="index"
                                  v-model="model_prestations_non_incluse"
                                  :label="options"
                                  :value="options"
                                  :id="options"
                                  class="text-checkbox tout-color"
                                ></v-checkbox>
                              </b-form-group>

                              <div class="add-nexw">
                                <b-form-group
                                  id="label-prestation-non-include"
                                  class="label-form mt-4"
                                >
                                  <b-form-input
                                    id="prestation-non-include-circuit"
                                    type="text"
                                    class="input-add-circuit"
                                    v-model="prestation_value_non_inclus"
                                  ></b-form-input>
                                </b-form-group>

                                <div class="add-container">
                                  <div class="head-add">
                                    <button
                                      @click.prevent="
                                        add_prestation_non_inclu_value
                                      "
                                      class="add-button1"
                                    >
                                      Ajouter
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="mt-4 save-container text-center">
                        <button class="btn add-button padding-bnt-crs save">
                          {{ button_name_provider }}
                          <pulse-loader
                            :loading="loading_provider"
                            :color="color"
                          ></pulse-loader>
                        </button>
                      </div>
                    </b-form>
                  </div>
                </div>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card color="basil" flat>
              <v-card-text>
                <div class="add-best-point-container card">
                  <div class="card-header">Points forts</div>

                  <div class="card-body">
                    <!-- card incluse -->
                    <div class="mt-2 card card-best-point card-border">
                      <div class="card-header">
                        Rédigez les points forts de votre circuit
                      </div>

                      <div class="card-body">
                        <b-form @submit.prevent="saveOrUpdateStregth">
                          <div>
                            <b-form-group
                              id="label-best-point"
                              class="label-form mt-4 m5"
                            >
                              <b-input-group
                                v-for="(input, k) in inputs"
                                :key="k"
                                :class="{ 'mt-3': input != inputs[0] }"
                              >
                                <template #append>
                                  <b-input-group-text class="exea">
                                    <button
                                      @click.prevent="addStreng(k)"
                                      class="type-number-container"
                                    >
                                      +
                                    </button>
                                    <button
                                      v-if="inputs.length > 1"
                                      @click.prevent="removeStreng(k)"
                                      class="type-number-container"
                                    >
                                      -
                                    </button>
                                  </b-input-group-text>
                                </template>
                                <b-form-input
                                  id="best-point-circuit"
                                  type="text"
                                  class="input-add-circuit haute-spec"
                                  placeholder="Description de la  visite"
                                  v-model="stregths[k]"
                                ></b-form-input>
                              </b-input-group>
                              <div class="mt-4 save-container text-center">
                                <button
                                  class="btn add-button padding-bnt-crs save"
                                >
                                  {{ button_name_stregth }}
                                  <pulse-loader
                                    :loading="loading_stregth"
                                    :color="color"
                                  ></pulse-loader>
                                </button>
                              </div>
                            </b-form-group>
                          </div>
                        </b-form>
                      </div>
                    </div>
                  </div>
                </div>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card color="basil" flat>
              <v-card-text>
                <div class="add-circuit-tag-container card">
                  <div class="card-header">Types de circuit et tags</div>

                  <div class="card-body">
                    <b-form @submit.prevent="saveOrUpdateTypeTag">
                      <!-- card incluse -->
                      <div class="row">
                        <div class="col-md-6">
                          <div class="mt-2 card card-circuit-tag card-border">
                            <div class="card-header">Types de circuit</div>

                            <div class="card-body">
                              <b-form-checkbox
                                v-for="(type, tp) in types"
                                :key="tp"
                                :id="'type-' + tp"
                                :name="'type-' + tp"
                                :value="type.value"
                                v-model="categorys_seo"
                              >
                                <span>{{ type.text }}</span>
                              </b-form-checkbox>
                            </div>
                          </div>
                        </div>

                        <div class="col-md-6">
                          <div
                            class="mt-2 card card-circuit-tag card-border card-tag"
                          >
                            <div class="card-header">Tags</div>

                            <div class="card-body">
                              <b-form-checkbox
                                v-for="(tag, ta) in tags"
                                :key="ta"
                                :id="'tag-' + ta"
                                :name="'tag-' + ta"
                                :value="tag.value"
                                v-model="taglist"
                              >
                                <span>{{ tag.text }}</span>
                              </b-form-checkbox>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="mt-4">
                        <b-form-group
                          class="label-form tout-color"
                          label="Ajout d'autre mot clé (tag)"
                        >
                          <vue-select
                            v-model="inputtags"
                            class="form-control-select multiple-select tout-color"
                            :options="[
                              'Camping',
                              'Natation',
                              'Karaoke',
                              'Visite musée',
                              'Salle de jeux',
                            ]"
                            name="program_activity"
                            :multiple="true"
                            :taggable="true"
                          >
                            <template v-slot:open-indicator>
                              <!-- <i class="mdi menu-down"></i> -->
                              <v-icon> mdi-menu-down</v-icon>
                            </template>
                          </vue-select>
                        </b-form-group>
                      </div>

                      <div class="mt-4 save-container text-center">
                        <button class="btn add-button padding-bnt-crs save">
                          {{ button_name_type }}
                          <pulse-loader
                            :loading="loading_type"
                            :color="color"
                          ></pulse-loader>
                        </button>
                      </div>
                    </b-form>
                  </div>
                </div>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card color="basil" flat>
              <v-card-text>
                <!-- GAllery image  -->
                <div class="add-program-container card">
                  <v-col
                    v-for="(image, id) in all_images"
                    :key="id"
                    class="d-flex child-flex"
                    cols="6"
                    xl="4"
                    lg="4"
                    md="6"
                    sm="6"
                    xs="12"
                  >
                    <v-checkbox
                      :key="id"
                      class="checkbox-class"
                      multiple
                      :disabled="
                        galleries.length >= 20 &&
                        galleries.indexOf(image.id) != id
                          ? true
                          : false
                      "
                      v-model="galleries"
                      :value="image.id"
                    ></v-checkbox>
                    <v-img
                      :src="`${path_url_images}${image.path}${image.filename}`"
                      :lazy-src="`${path_url_images}${image.path}${image.filename}`"
                      aspect-ratio="1"
                      class="grey lighten-2"
                    >
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height ma-0"
                          align="center"
                          justify="center"
                        >
                          <v-progress-circular
                            indeterminate
                            color="grey lighten-5"
                          ></v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>
                  </v-col>
                  <v-col class="d-flex child-flex" cols="12">
                    <div
                      class="cnt-link-tour w-100 text-center cnt-btn-tour mt-0"
                    >
                      <button
                        type="submit"
                        @click="send_all_data()"
                        class="btn add-button padding-bnt-crs save"
                      >
                        Valider
                      </button>
                    </div>
                  </v-col>
                </div>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card color="basil" flat>
              <v-card-text>
                <div class="card">
                  <div class="card-header">Informations pratiques</div>

                  <div class="card-body">
                    <div
                      class="card mb-2"
                      v-for="(infopratique, i) in infopratiques.contents"
                      :key="'infopratique' + i"
                    >
                      <div class="card-header">
                        <div class="row widthoutpad">
                          <div class="col-lg-2 d-flex align-items-center">
                            Information {{ i + 1 }}
                          </div>
                          <div class="col-lg-10">
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Titre information (Exemple: Visa en Arménie)"
                              v-model="infopratiques.contents[i].title"
                            />
                          </div>
                        </div>
                      </div>

                      <draggable
                        group="people"
                        @start="drag = true"
                        @end="drag = false"
                        v-model="infopratique.contents"
                      >
                        <div
                          class="card-body"
                          v-for="(content, j) in infopratique.contents"
                          :key="'content' + j"
                        >
                          <input
                            type="text"
                            class="form-control mb-2"
                            placeholder="Titre contenue (Example: Comment est le Visa en Arménie ?)"
                            v-model="
                              infopratiques.contents[i].contents[j].title
                            "
                          />
                          <textarea
                            class="form-control"
                            rows="5"
                            placeholder="Description"
                            v-model="
                              infopratiques.contents[i].contents[j].description
                            "
                          ></textarea>
                          <div
                            class="d-flex align-items-center justify-content-end mt-2"
                          >
                            <div
                              v-if="
                                infopratique.contents.length > 1 ||
                                infopratiques.contents[i].id
                              "
                            >
                              <v-btn
                                class="mx-2"
                                fab
                                dark
                                small
                                color="#FF0000"
                                @click="remove_info_content(i, j)"
                              >
                                <v-icon dark> mdi-minus</v-icon>
                              </v-btn>
                            </div>

                            <div v-if="j == infopratique.contents.length - 1">
                              <v-btn
                                class="mx-2"
                                fab
                                dark
                                small
                                color="#F7941D"
                                @click="add_info_content(i, j)"
                              >
                                <v-icon dark> mdi-plus</v-icon>
                              </v-btn>
                            </div>
                          </div>
                        </div>
                      </draggable>

                      <div class="card-footer">
                        <div
                          class="d-flex align-items-center justify-content-start"
                        >
                          <div
                            v-if="
                              infopratiques.contents.length > 1 ||
                              infopratique.id
                            "
                          >
                            <v-btn
                              class="mx-2"
                              fab
                              dark
                              small
                              color="#FF0000"
                              @click="remove_info_pratique(i)"
                            >
                              <v-icon dark> mdi-minus</v-icon>
                            </v-btn>
                          </div>

                          <div v-if="i == infopratiques.contents.length - 1">
                            <v-btn
                              class="mx-2"
                              fab
                              dark
                              small
                              color="#F7941D"
                              @click="add_info_pratique(i)"
                            >
                              <v-icon dark> mdi-plus</v-icon>
                            </v-btn>
                          </div>

                          <div class="col-md-2" v-if="valid_info(infopratique)">
                            <v-btn
                              class="mx-2"
                              fab
                              dark
                              small
                              color="#00A2A1"
                              @click.prevent="valider_info_pratique(i)"
                            >
                              <v-icon dark> mdi-check</v-icon>
                            </v-btn>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </div>
  </div>
</template>
<style lang="css" scoped>
@import url("https://fonts.googleapis.com/css2?family=Dancing+Script:wght@600&display=swap");

.add-circuit-saison-container .card-body {
  height: 800px !important;
}

h1.font-weight-bold.text-h2.basil--text {
  font-family: "Dancing Script", cursive;
  color: #f7941d;
}

.form-price-edit {
  border: 1px solid;
  width: 105px;
  height: 33px;
  display: inline;
  border-radius: 4px;
  opacity: 0.5;
}

.date-time-picker {
  display: inline;
}

.date-time-picker {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  display: inline !important;
}

.new-cols {
  max-width: 100%;
}

.ds {
  height: 39px;
  margin-left: 5px;
}

input.nb_minMax_props {
  border: 1px #c7c7c7 solid;
  height: 39px;
  border-radius: 7px;
  width: 55px;
  padding-left: 8px;
}

.center-nd {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.ds:first-child {
  margin-left: 10px;
}

.center-nd {
  text-align: center;
}

.card-header.edz {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

button.lm-btn {
  background-color: #00a2a1 !important;
  margin-left: 20px !important;
}

.widthoutpad {
  margin: 0;
}

button.btn.btn-primary.ml-5 {
  background-color: #00a2a1;
  border: none;
  font-weight: bold;
}

.content_saison {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.content_saison .btn_plus_saison {
  font-size: 25px;
  color: #f89b1d;
  margin-left: 25px;
}

.content_trance_saison i.fa.fa-minus {
  width: 20px;
  height: 20px;
  text-align: center;
  background-color: red;
  color: #fff;
  border-radius: 50%;
  position: absolute;
  padding-top: 3px;
  margin-left: -10px;
  margin-top: -10px;
}

.content_trance_saison {
  margin-bottom: 15px;
}

.v-input.text-checkbox.tout-color.theme--light.v-input--selection-controls.v-input--checkbox
  label.v-label.theme--light {
  margin: 0;
}

.input-group-text.exea {
  padding: 0;
  height: 45px;
}

button.add-button1 {
  height: 47px;
  width: 70px;
  background-color: #f7941d;
  color: #fff;
  font-weight: bold;
  border-radius: 0 5px 5px 0;
}

label.v-label.theme--light {
  margin-bottom: 0;
}

.newscroll {
  height: 460px;
}

.file-upload-form1.un img {
  width: 100% !important;
  height: 100% !important;
  object-fit: cover !important;
}

.text-checkbox label {
  margin-bottom: 0;
}

.file-upload-form1.un img.preview {
  width: 100% !important;
  height: 100% !important;
  object-fit: cover;
}

input#prestation-non-include-circuit,
input#prestation-circuit {
  border-radius: 5px 0 0 5px;
  height: 47px;
}

fieldset#label-prestation-non-include,
fieldset#label-prestation {
  width: calc(100% - 70px);
  margin: 0 !important;
  border-right: none !important;
  border-radius: 5px 0 0 5px;
}

img.preview {
  width: 100% !important;
  height: 340px !important;
  object-fit: cover;
}

.add-nexw {
  display: flex;
  align-items: center;
  max-height: 50px;
  position: absolute;
  bottom: 25px;
  width: calc(100% - 60px);
}

input#best-point-circuit {
  height: 44px;
}

.columns-checkbox {
  max-height: 350px;
  height: 350px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.columns-checkbox::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

.columns-checkbox::-webkit-scrollbar {
  width: 10px;
  background-color: #f5f5f5;
}

.columns-checkbox::-webkit-scrollbar-thumb {
  background-color: #00a2a1;

  background-image: -webkit-gradient(
    linear,
    0 0,
    0 100%,
    color-stop(0.5, rgba(255, 255, 255, 0.2)),
    color-stop(0.5, transparent),
    to(transparent)
  );
}

input.none {
  opacity: 0;
  position: absolute;
  width: 100% !important;
  height: 100% !important;
  object-fit: cover;
}

input.haute-spec {
  height: 44px;
}

.file-upload-form1 img.preview,
.file-upload-form1 .image-preview {
  width: 100% !important;
  height: 100% !important;
  object-fit: cover;
}

.input-group-text.exea button.type-number-container {
  padding: 9px 15px;
  border: 1px #ced4da solid;
  font-weight: bold;
  color: #000;
}

.er i {
  background-color: #f79622;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin: 5px;
}

.er {
  display: flex;
}

button.btn.add-button.padding-bnt-crs.save {
  background-color: #00a2a1;
}

.file-upload-form label {
  position: absolute;
  margin: 0;
  width: 100% !important;
  height: 100% !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.aption_delete {
  padding-left: 0;
  width: 18px;
  color: red;
  cursor: pointer;
}

.option_edit {
  margin-right: 10px;
  cursor: pointer;
  color: #00a2a1;
}

.mt-4.add-jour-container {
  display: flex;
  justify-content: space-around;
}

.v-label {
  margin-bottom: 0 !important;
}

.tec-center {
  text-align: center;
  display: flex;
}

.tec-center .add-traveler-option {
  padding-top: 0;
  width: 18px;
  padding-left: 0;
}

.supp {
  display: flex;
  width: 177px;
  justify-content: space-between;
  align-items: center;
}

.add {
  display: flex;
  width: 177px;
  justify-content: center;
  align-items: center;
}

.file-upload-form1 label {
  position: absolute;
  margin: 0;
  width: 95.5%;
  height: 64%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.head-add.flex {
  text-align: right;
}

.file-upload-form i.fa.fa-camera,
.file-upload-form1 i.fa.fa-camera {
  font-size: 60px;
  color: #f79622;
}

.hebergement {
  max-width: calc(50% - 10px);
}

.card-header,
.card-footer {
  background-color: #00a2a1;
  color: #fff;
}

.card-header {
  text-align: center;
}

.file-upload-form,
.file-upload-form1 {
  width: 100% !important;
  height: 340px !important;
}

img.preview1 {
  width: 100% !important;
  height: 100% !important;
  object-fit: cover !important;
}

button.btn.add-button.padding-bnt-crs {
  background-color: #f79622;
  color: #fff;
  box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);
}

button.btn.del-moment {
  box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);
}

.input-refont {
  height: 40px;
}

.bord-colr {
  border: 1px #bebebe solid !important;
}

.mt-8.px-0.card.card-program.list-parcours {
  margin-top: 15px;
}

.v-select.form-control-select.multiple-select.vs--multiple.vs--searchable {
  background-color: #fff;
  border-radius: 5px;
}

.form-group.label-form {
  margin-bottom: 5px;
}

.card-header.ex1 {
  border-bottom: none;
  text-align: center;
}

.day-title {
  background-color: #f79622;
  color: #fff;
  text-align: center;
  margin-bottom: 15px;
  padding: 6px;
  border-radius: 5px;
}

@media (max-width: 1412px) {
  .bt {
    margin-top: 10px;
  }
}
</style>
<script>
import Vue from "vue";
import Axios from "axios";
import imageCompressor from "vue-image-compressor";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";

import draggable from "vuedraggable";
import moment from "moment";
import VueCtkDateTimePicker from "vue-ctk-date-time-picker";
import "vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css";
import VueGoogleAutocomplete from "vue-google-autocomplete";

Vue.component("vue-google-autocomplete", VueGoogleAutocomplete);

Vue.component("VueCtkDateTimePicker", VueCtkDateTimePicker);
Vue.component("image-compressor", imageCompressor);
import { BootstrapVue } from "bootstrap-vue";

Vue.use(BootstrapVue);
import vSelect from "vue-select";

Vue.component("vue-select", vSelect);
import "vue-select/dist/vue-select.css";

Vue.component("PulseLoader", PulseLoader);

// Import Bootstrap an BootstrapVue CSS files (order is important)
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import Vuelidate from "vuelidate";

Vue.use(Vuelidate);
const {
  required,
  minValue,
  minLength,
  requiredIf,
} = require("vuelidate/lib/validators");

export default {
  components: {
    draggable,
  },
  name: "update-tour",

  props: {
    copydata: Object,
    id_circuit: String,
    model_circuit: String,
  },
  data() {
    return {
      // Satate for the saison
      saison_data: [],
      dataCircuit: [],
      prestations_incluse: [],
      saison_date: [],
      saison: "program",
      loading_saison: false,
      button_name_saison: "Enregistrer saison",
      demo: {
        id: "2",
        options: {
          noButtonNow: true,
          noShortcuts: true,
          noButton: false,
          slot: {
            type: "button",
          },
          range: true,
          formatted: "ll",
          format: "YYYY-MM-DD",
          color: "#f26923",
          buttonColor: "#f26923",
          label: "Choisir une saison",
          id: "RangeDatePicker",
        },
      },
      // State for image gallery
      all_images: [],
      galleries: [],

      // state for tags
      types: [{ value: null, text: null }],
      categorys_seo: [],
      tags: [],
      taglist: [],
      autocompleteTags: [],
      inputtags: [],
      button_name_type: "Enregistrer et publier",
      loading_type: false,
      // state for the best point
      button_name_stregth: "Enregistrer ces points forts",
      loading_stregth: false,
      stregths: [],
      circuit_id: this.$route.params.circuit,
      circuit_copy_id: null,
      // state for prestation
      button_name_provider: "Enregistrer ces prestations",
      loading_provider: false,
      description_prestations: [],
      prestation_value: "",
      prestation_value_non_inclus: "",
      model_prestations_incluse: [],
      inputs: [""],
      incluses_on: [],
      incluses_off: [],

      option_prestations_incluse: [
        "Tous les transferts et les excursions pendant le tour",
        "Chauffeur francophone",
        "Dégustations de vins",
        "Rafraîchissement pendant le voyage",
        "Frais d'entrée aux sites historiques et culturels",
        "Taxes locales",
      ],
      // non_inclus
      model_prestations_non_incluse: [],
      option_prestations_non_inclse: [
        "Le billet d'avion",
        "Les assurances",
        "Dégustations de vins",
      ],
      // state for price
      nb_voyageurs: [
        "01-02",
        "03-04",
        "05-06",
        "07-08",
        "09-10",
        "11-12",
        "13-14",
        "15-16",
        "17-18",
        "19-20",
      ],
      garantie_prix: "",
      loading_price: false,
      color_price: "white",
      color: "white",
      nb_min: null,
      nb_max: null,
      indexEdit: null,
      price_etoiles: {
        price: "",
        deux_etoiles: "",
        trois_etoiles: "",
        quatres_etoiles: "",
        cinq_etoiles: "",
        mixte_cat1: "",
        mixte_cat2: "",
        mixte_cat3: "",
        mixte_price: "",
        save: true,
      },
      button_name_price: "Enregistrer ce prix",
      input_val_tranch: false,
      cat_prix: [],
      selected_index_vg: 0,
      all_nb_voyage: {},
      // state for  programe
      parcours: [
        [],
        {
          title: "",
          departure_city: "",
          arrival_city: "",
        },
      ],
      visites: {
        visite_cover: "",
        description_visite: "",
        visitenameCover: "",
      },
      tab: null,
      applique_tous_jour: false,
      applique_tous_jour_pension: false,
      applique_tous_jour_guide_chauffeur: false,
      scaleP: 92,
      quality: 50,
      qualityP: 50,
      originalSize: true,
      base_url: window.baseUrl + "/api/",

      //   base_url: "https://admin.naoh-voyages.local:8890/api/",
      path_url_images: "https://noah-voyages.com/images/",
      base_url_back: window.baseUrl + "/admin/",
      activites: [
        { name_activite: "Plongée", id_activite: "1" },
        { name_activite: "Randonnée", id_activite: "2" },
        { name_activite: "Alpinisme", id_activite: "3" },
        { name_activite: "Baignade", id_activite: "4" },
        { name_activite: "Biking", id_activite: "5" },
        { name_activite: "Camping", id_activite: "6" },
        { name_activite: "Canoë", id_activite: "7" },
        { name_activite: "Croisière", id_activite: "8" },
        { name_activite: "Croisière à la voile", id_activite: "8" },
        { name_activite: "Cyclisme", id_activite: "9" },
        { name_activite: "Dîner croisière", id_activite: "10" },
        { name_activite: "Diving", id_activite: "11" },
        { name_activite: "Équitation", id_activite: "12" },
        { name_activite: "Escalade", id_activite: "13" },
        { name_activite: "Expéditions", id_activite: "14" },
        { name_activite: "Faune et Flore", id_activite: "15" },
        { name_activite: "Festival", id_activite: "16" },
        { name_activite: "Gastronomie", id_activite: "17" },
        { name_activite: "Golf", id_activite: "18" },
        { name_activite: "Hiking", id_activite: "19" },
        { name_activite: "Shopping", id_activite: "20" },
        { name_activite: "Montagne", id_activite: "21" },
        { name_activite: "Parc d’attraction", id_activite: "23" },
        { name_activite: "Patin", id_activite: "24" },
        { name_activite: "Plongée sous-marine", id_activite: "26" },
        { name_activite: "Polaire", id_activite: "27" },
        { name_activite: "Randonnée", id_activite: "28" },
        { name_activite: "Raquette", id_activite: "29" },
        { name_activite: "Safari", id_activite: "30" },
        { name_activite: "Ski", id_activite: "31" },
        { name_activite: "Snorkeling", id_activite: "32" },
        { name_activite: "Visite culturelle", id_activite: "33" },
      ],
      all_day: [
        {
          id: null,
          nom_hebergement: "",
          cat_hebergement: "",
          url: "",
          photo: ["", "", "", ""],
          dataImage: ["", "", "", ""],
          nameCover: ["", "", "", ""],
          petit_dejeuner: false,
          dejeuner: false,
          dinner: false,
          guide: false,
          chauffeur: false,
          activiter: "",
          parcour: [
            {
              index: 1,
              title_itineraire: "",
              departure_city: "",
              arrival_city: "",
              path_length: "",
              travel_time: "",
              visites: [
                {
                  index: 1,
                  photo: "",
                  description_visite: "",
                  visitenameCover: "",
                },
              ],
            },
          ],
          save: false,
          msgBtnEnregistrement: "Enregistrer cette journée",
        },
      ],
      imgPathHost: "https://noah-voyages.com/images/hosts/",
      stadings: [
        {
          value: null,
          text: "Choisir la categorie de l'hebergement",
          disabled: true,
        },
      ],
      index_photo_heberge: "",
      index_day: "",
      scale: 80,
      original: null,
      imgPathVisite: "https://noah-voyages.com/images/visites/",
      disabled_dates: ["03-03-2021", "05-04-2021"],
      isLoading: false,

      defaultPrestation: {
        inclus: [
          "Tous les transfert et excursions pendant le tour",
          "Chauffeur francophone','Dégustation de vins",
          "Rafraîchissements pendant le voyage",
          "Frais d'entrée aux sites historiques et culturels",
          "Taxes locales",
          "Dégustations de vins",
        ],
        ninclus: [
          "Le billet d'avion",
          "Les assurances",
          "Tous les transfert et excursions pendant le tour",
          "portiers et dépenses supplémentaires (appels, minibar, etc.) à l'hôtel",
          "Push",
        ],
      },
      prestations_circuits: [],
      tags_circuit: [],
      types_circuit: [],
      description_prestation: [],
      prestations_non_incluse: [],
      nInclus: [
        {
          ninc: "",
        },
      ],
      inclus: [
        {
          inc: "",
        },
      ],

      // State for infroamation pratique
      about: {
        id_type: null,
        title: null,
        contents: [
          {
            title: null,
            value: null,
          },
        ],

        valide: false,
      },

      infopratiques: {
        contents: [
          {
            title: null,
            contents: [
              {
                title: null,
                description: null,
              },
            ],
          },
        ],
        valide: false,
      },
      infos: [],
      is_mixed_price: false,
      //  depart garantie
      guarted_departure: false,
      tabg: null,
      garanties: [
        {
          day: [],
          dates: [],
          month: [new Date().getMonth() + 1],
          isAllWeek: false,
          isAllYear: false,
          isAllDay: false,
          isAllmonth: false,
          choix: [],
          full_week: [0, 1, 2, 3, 4, 5, 6],
          all_date_disabled: [],
          enable_date: [],
          day_data: [],
          isGetDay: false,
          is_day_selected: false,
          monthSelected: [],
          all_month_selected: false,
          dateWithoutAllMonth: [],
          open: false,
        },
      ],

      weeks: [
        { name_activite: "Lundi", id_activite: "1" },
        { name_activite: "Mardi", id_activite: "2" },
        { name_activite: "Mercredi", id_activite: "3" },
        { name_activite: "Jeudi", id_activite: "4" },
        { name_activite: "Vendredi", id_activite: "5" },
        { name_activite: "Samedi", id_activite: "6" },
        { name_activite: "Dimanche", id_activite: "0" },
      ],
      months: [
        { name: "Janvier", id_activite: 1 },
        { name: "Février", id_activite: 2 },
        { name: "Mars", id_activite: 3 },
        { name: "Avril", id_activite: 4 },
        { name: "Mai", id_activite: 5 },
        { name: "Juin", id_activite: 6 },
        { name: "Juillet", id_activite: 7 },
        { name: "Août", id_activite: 8 },
        { name: "Septembre", id_activite: 9 },
        { name: "Octobre", id_activite: 10 },
        { name: "Novembre", id_activite: 11 },
        { name: "Décembre", id_activite: 12 },
      ],
      week_selected: [],
      test: null,
      dates: null,
      menu: false,
      showDay: false,
      radioGroup: 1,
      items: ["Pour tous les mois", "Pour toutes les années"],
      model: [],
      showDate: false,
      all_date_disabled: [],
      month: null,
      year: false,
      count: 0,
      d: new Date(),
      address2: "",
      address: {
        administrative_area_level_1: "Nouvelle-Aquitaine",
        administrative_area_level_2: "Gironde",
        country: "France",
        latitude: 44.631077,
        locality: "La Teste-de-Buch",
        longitude: -1.148224,
      },
      saison_price_data: [],
      tabSaison: null,
      copy: this.copydata,
    };
  },
  validations: {
    saison_price_data: {
      required,
      minLength: minLength(1),
      $each: {
        title: { required },
        garantie_prix: {
          required: requiredIf(function () {
            return this.guarted_departure;
          }),
          minValue: minValue(1),
        },
        saison_id: {
          required: requiredIf(function () {
            return this.saison_data.length > 0;
          }),
        },
        tranche: {
          required: requiredIf(function () {
            return !this.guarted_departure;
          }),
        },
        price_etoiles: {
          price: {
            required: requiredIf(function (obj) {
              return (
                this.cat_prix.length == 1 &&
                !this.guarted_departure &&
                !obj.price &&
                !obj.deux_etoiles &&
                !obj.trois_etoiles &&
                !obj.quatres_etoiles &&
                !obj.cinq_etoiles
              );
            }),
          },
          deux_etoiles: {
            required: requiredIf(function (obj) {
              return (
                this.cat_prix.length == 1 &&
                !this.guarted_departure &&
                !obj.price &&
                !obj.deux_etoiles &&
                !obj.trois_etoiles &&
                !obj.quatres_etoiles &&
                !obj.cinq_etoiles
              );
            }),
          },
          trois_etoiles: {
            required: requiredIf(function (obj) {
              return (
                this.cat_prix.length == 1 &&
                !this.guarted_departure &&
                !obj.price &&
                !obj.deux_etoiles &&
                !obj.trois_etoiles &&
                !obj.quatres_etoiles &&
                !obj.cinq_etoiles
              );
            }),
          },
          quatres_etoiles: {
            required: requiredIf(function (obj) {
              return (
                this.cat_prix.length == 1 &&
                !this.guarted_departure &&
                !obj.price &&
                !obj.deux_etoiles &&
                !obj.trois_etoiles &&
                !obj.quatres_etoiles &&
                !obj.cinq_etoiles
              );
            }),
          },
          cinq_etoiles: {
            required: requiredIf(function (obj) {
              return (
                this.cat_prix.length == 1 &&
                !this.guarted_departure &&
                !obj.price &&
                !obj.deux_etoiles &&
                !obj.trois_etoiles &&
                !obj.quatres_etoiles &&
                !obj.cinq_etoiles
              );
            }),
          },
          mixte_cat1: {
            required: requiredIf(function () {
              return !this.guarted_departure && this.cat_prix.length > 1;
            }),
          },
          mixte_cat2: {
            required: requiredIf(function () {
              return !this.guarted_departure && this.cat_prix.length > 1;
            }),
          },
          // mixte_cat3: {
          //   required: requiredIf(function () {
          //     return (!this.guarted_departure && this.cat_prix.length > 1)
          //   }),
          // },
          mixte_price: {
            required: requiredIf(function () {
              return !this.guarted_departure && this.cat_prix.length > 1;
            }),
          },
        },
      },
    },
  },
  methods: {
    // All methods for information pratique
    filterNbVoyage(nb) {
      // console.log(typeof nb)
      let nbFilter = String(nb).split("-");
      nbFilter = nbFilter[0] == nbFilter[1] ? nbFilter[0] : nb;
      return nbFilter;
    },

    // add new season

    newsaison() {
      if (!this.guarted_departure) {
        this.saison_price_data.push({
          title: "nouveau",
          id: "",
          tranche: 1,
          saison_id: [],
          is_new: true,
          input_val_tranch: false,
          price_etoiles: {
            price: "",
            deux_etoiles: "",
            trois_etoiles: "",
            quatres_etoiles: "",
            cinq_etoiles: "",
            mixte_cat1: this.cat_prix[0] ? this.cat_prix[0] : "",
            mixte_cat2: this.cat_prix[1] ? this.cat_prix[1] : "",
            mixte_cat3: this.cat_prix[2] ? this.cat_prix[2] : "",
            mixte_price: "",
          },
        });
        this.all_nb_voyage[this.saison_price_data.length - 1] = [];
        this.all_nb_voyage[this.saison_price_data.length - 1][1] = {
          price_etoiles: {
            price: "",
            deux_etoiles: "",
            trois_etoiles: "",
            quatres_etoiles: "",
            cinq_etoiles: "",
            mixte_cat1: this.cat_prix[0] ? this.cat_prix[0] : "",
            mixte_cat2: this.cat_prix[1] ? this.cat_prix[1] : "",
            mixte_cat3: this.cat_prix[2] ? this.cat_prix[2] : "",
            mixte_price: "",
          },
        };
      } else if (this.guarted_departure) {
        this.saison_price_data.push({
          title: "saison 1",
          id: "",
          garantie_prix: 0,
          saison_id: [],
        });
      }
    },
    deletePriceSaison(index) {
      let delete_saison_price = this.saison_price_data[index];
      let models = "";
      if (!this.guarted_departure && this.cat_prix.length == 1) {
        models = "App\\SimplePrices";
      } else if (!this.guarted_departure && this.cat_prix.length > 1) {
        models = "App\\MixedPrices";
      } else if (this.guarted_departure) {
        models = "App\\GarantiePrix";
      }
      let params = {
        model: models,
        id: delete_saison_price.id,
      };
      if (delete_saison_price.id) {
        this.requestDeleteSeasonPrice(params,index);
      } else {
        this.saison_price_data.splice(index, 1);
      }
    },
    requestDeleteSeasonPrice(params, index) {
      Axios.post(this.base_url + "receptive/delete/season_price", {
        params: params,
      })
        .then((response) => {
          if (response.data.state == "success") {
            alert("Suppression avec succès.");
            this.saison_price_data.splice(index, 1);
          } else {
            alert("Suppression échoué.");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    valid_info_contents(content) {
      if (content) {
        let valid = content.findIndex(
          (item) => !item.title || !item.description
        );
        return valid == -1 ? true : false;
      }
      return true;
    },

    valid_info(content) {
      var valid_title = content.title ? true : false;
      var valid_content = this.valid_info_contents(content.contents);

      return valid_title && valid_content ? true : false;
    },

    add_about_content() {
      this.about.contents.push({
        title: null,
        value: null,
      });
    },

    remove_about_content(i) {
      if (this.about.contents[i].id) {
        if (confirm("Supprimer ce contenu ?")) {
          Axios.post(
            this.base_url +
              "destinations/about/delete/" +
              this.about.contents[i].id
          ).then((response) => {
            if (response.data.error) {
              this.$toaster.error("Une erreur est survenue");
            }

            if (response.data.success) {
              this.$toaster.success("Informations enregistrées ");
              this.about.contents.splice(i, 1);
              // window.location.reload();
            }
          });
        }
      } else {
        this.about.contents.splice(i, 1);
      }
    },

    valider_about() {
      Axios.post(this.base_url + "destinations/about", {
        ...this.about,
        id_type: this.destination.id,
        type: "Destinations",
      }).then((response) => {
        if (response.data.warning || response.data.error) {
          this.$toaster.error("Une erreur est survenue");
        }

        if (response.data.success) {
          this.$toaster.success("Informations enregistrées");
          // window.location.reload();
        }
      });
    },

    add_info_pratique(i) {
      this.infopratiques.contents.push({
        title: null,
        contents: [
          {
            title: null,
            description: null,
          },
        ],
      });
    },

    remove_info_pratique(i) {
      if (this.infopratiques.contents[i].id) {
        if (confirm("Supprimer ce contenu ?")) {
          Axios.post(
            this.base_url +
              "destinations/infopratique/delete/" +
              this.infopratiques.contents[i].id
          ).then((response) => {
            if (response.data.error) {
              this.$toaster.error("Une erreur est survenue");
            }

            if (response.data.success) {
              this.$toaster.success("Informations enregistrées");
              //   this.about.contents.splice(i, 1);
              this.infopratiques.contents.splice(i, 1);
              if (this.infopratiques.contents.length == 0) {
                this.add_info_pratique();
              }
              // window.location.reload();
            }
          });
        }
      } else {
        this.infopratiques.contents.splice(i, 1);
      }
    },

    add_info_content(i, j) {
      this.infopratiques.contents[i].contents.push({
        title: null,
        description: null,
      });
    },

    remove_info_content(i, j) {
      if (this.infopratiques.contents[i].contents[j].id) {
        if (confirm("Supprimer ce contenu ?")) {
          Axios.post(
            this.base_url +
              "destinations/infopratique/content/delete/" +
              this.infopratiques.contents[i].contents[j].id
          ).then((response) => {
            if (response.data.error) {
              this.$toaster.error("Une erreur est survenue");
            }

            if (response.data.success) {
              this.$toaster.success("Informations enregistrées");
              this.infopratiques.contents[i].contents.splice(j, 1);
            }
          });
        }
      } else {
        this.infopratiques.contents[i].contents.splice(j, 1);
      }
    },

    valider_info_pratique(i) {
      let model = this.model_circuit;
      let type = model.replace("App\\", "");
      // console.log(type)
      Axios.post(this.base_url + "destinations/infopratique", {
        infopratiques: this.infopratiques.contents[i],
        id: this.copy.id,
        type: type,
        model_circuit: this.model_circuit,
      }).then((response) => {
        console.log(response);
        if (response.data.warning || response.data.error) {
          this.$toaster.error("Une erreur est survenue");
        }

        if (response.data.success) {
          this.$toaster.success("Informations enregistrées");
          let data = {
            id: response.data.data[0].infopratiques_id,
            title: this.infopratiques.contents[i].title,
            contents: response.data.data,
          };
          this.infopratiques.contents[i] = data;
          // window.location.reload();
        }
      });
    },

    // Add all update
    allFinishAddCircuit() {
      let that = this;
      that.isLoading = true;
      Axios.post(this.base_url_back + "save", {
        circuit: that.copy.circuit_id,
        all_day: that.all_day,
        copy: that.copy.id,
      })
        .then((response) => {
          this.$toaster.success("Modification enregistrée");
          window.location.href = this.base_url_back + "circuits_back";
        })
        .catch((err) => {
          console.log(err);
          this.$toaster.error("Une erreur est survenue");
        });
    },
    // Methods for the saison
    formattageDate(d, lang = "fr") {
      var tabMois = [];
      if (lang == "fr") {
        tabMois = [
          "janv",
          "févr",
          "mars",
          "avr",
          "mai",
          "juin",
          "juil",
          "août",
          "sept",
          "oct",
          "nov",
          "déc",
        ];
      }
      return [d.getDate(), tabMois[d.getMonth()] + ".", d.getFullYear()].join(
        " "
      );
    },
    validateSaison(saison_date) {
      return new Promise((resolve, reject) => {
        var indexRemove = [];
        var saision_valid = [];
        saison_date.forEach((item_1, index_1) => {
          saison_date.forEach((item_2, index_2) => {
            if (
              new Date(item_1.value.start) <= new Date(item_2.value.start) &&
              new Date(item_1.value.end) >= new Date(item_2.value.end) &&
              item_1 != item_2
            ) {
              indexRemove.push(index_2);
            }
          });
          if (index_1 == saison_date.length - 1) {
            saison_date.forEach((item_3, index_3) => {
              if (indexRemove.indexOf(index_3) == -1) {
                saision_valid.push(item_3);
              }
              if (index_3 == saison_date.length - 1) {
                saison_date = [];
                resolve(saision_valid);
              }
            });
          }
        });
      });
    },
    changeSaison(saison) {
      this.saison = saison;
    },
    deleteArrayIndex(array, i) {
      let array_filtre = [];
      for (let n = 0; n < array.length; n++) {
        if (n != i) {
          array_filtre.push(array[n]);
        }
      }
      return array_filtre;
    },

    delete_saison(value) {
      if (this.saison_date[value].id) {
        if (confirm("Voulez-vous supprimer?") == true) {
          Axios.delete(this.base_url_back + "circuit/deleteSaison", {
            data: {
              id: this.saison_date[value].id,
            },
          }).then((response) => {
            console.log(response);
            if (response.data.success) {
              this.saison_date = this.deleteArrayIndex(this.saison_date, value);
              this.refreshSaison(this.saison_date);
              alert("suppression avec success");
            } else {
              alert("suppression non success");
            }
          });
        }
      } else {
        this.saison_date = this.deleteArrayIndex(this.saison_date, value);
      }

      this.mettrejourDateDisabled();
    },

    add_saison() {
      if (this.saison_date[this.saison_date.length - 1] != "") {
        this.saison_date.push({ value: { start: "", end: "" } });
      }
      this.mettrejourDateDisabled();
    },

    async refreshSaison(saison) {
      this.saison_data = [];

      await saison.forEach((element, i) => {
        this.saison_data.push({
          id: element.id,
          label: element.date_start + " à " + element.date_end,
        });
      });

      this.getSeason(this.dataCircuit);
    },
    /**
     *
     * @param value
     */
    saveOrUpdateSaison(value) {
      var that = this;

      if (that.guarted_departure) {
        that.loading_saison = true;
        that.button_name_saison = "";

        Axios.post(that.base_url_back + "circuit/updateSaison", {
          circuit_id: that.copy.circuit_id,
          garanties: this.garanties,
          circuit_copy_id: that.copy.id,
          model_circuit: this.model_circuit,
        })
          .then((response) => {
            console.log(response);

            this.refreshGarantie(response.data);
            that.loading_saison = false;
            that.button_name_saison =
              this.guarted_departure == 1
                ? "Enregistrer les dates de départ"
                : "Enregistrer saison";
            that.$toaster.success("Date de départ ajoutée avec succès.");
          })
          .catch(function (error) {
            console.log(error);
            that.$toaster.error(
              "Une erreur est survenu lors de votre enregistrement"
            );
            that.loading_saison = false;
            that.button_name_saison =
              this.guarted_departure == 1
                ? "Enregistrer les dates de départ"
                : "Enregistrer saison";
          });
      } else if (that.checkDataSaison(that.saison_date)) {
        that.validateSaison(that.saison_date).then(function (saision_valid) {
          that.loading_saison = true;
          that.button_name_saison = "";
          that.saison_date = saision_valid;
          that.disabled_dates = [];
          saision_valid.forEach((el, i) => {
            document.querySelectorAll(".content_trance_saison .lm-btn")[
              i
            ].textContent =
              that.formattageDate(new Date(el.value.start)) +
              " - " +
              that.formattageDate(new Date(el.value.end));
          });

          Axios.post(that.base_url_back + "circuit/updateSaison", {
            circuit_id: that.copy.circuit_id,
            saison: saision_valid,
            circuit_copy_id: that.copy.id,
            model_circuit: that.model_circuit,
          })
            .then((res) => {
              console.log("res saison", res);
         
              that.getCircuit();
              that.loading_saison = false;
              that.button_name_saison = "Enregistrer la saison";
              that.$toaster.success("Saison ajoutée avec succès.");
            })
            .catch((err) => {
              console.log(err);
              that.$toaster.error(
                "Une erreur est survenu lors de votre enregistrement"
              );
              that.loading_saison = false;
              that.button_name_saison = "Enregistrer saison";
            });
        });
      } else {
        that.$toaster.error(
          "Veuillez  vérifier la date du  début et fin de chaque saison"
        );
        that.loading_saison = false;
        that.button_name_saison = "Enregistrer saison";
      }
    },
    checkDataSaison() {
      var valeur = true;
      this.saison_date.forEach((el) => {
        if (
          el.value == "" ||
          el.value.start == "" ||
          el.value.end == "" ||
          el.value.start == null ||
          el.value.end == null
        ) {
          valeur = false;
        }
      });
      return valeur;
    },

    // Methods of garantie

    /**
     * When the date change
     * @param index number
     * @return void
     */
    onChangeDate(index) {
      if (this.garanties[index].is_day_selected == false) {
        if (!this.garanties[index].open) {
          alert("Veuillez choir entre date et jour");
        }
      }
    },

    /**
     * Get all garanties
     */
    getGarantieData(guarted_data) {
      console.log("data generated", guarted_data);
      if (this.guarted_departure) {
        this.button_name_saison = "Enregistrer les dates de départ";
      }
      this.garanties = [];
      var getKey = [];
      guarted_data.forEach((guar, key) => {
        console.log("guar", guar);
        getKey.push(key);
        this.garanties.push({
          id: guar.id,
          day: guar.day != null ? guar.day : [],
          dates: guar.dates ? guar.dates : [],
          month: guar.month ? guar.month : [],
          isAllWeek: Boolean(guar.isAllWeek),
          isAllYear: Boolean(guar.isAllYear),
          isAllDay: Boolean(guar.isAllDay),
          isAllmonth: guar.month.length <= 1 ? false : true,
          choix:
            guar.month.length == 12
              ? Boolean(guar.isAllYear)
                ? ["Pour tous les mois", "Pour toutes les années"]
                : ["Pour tous les mois"]
              : Boolean(guar.isAllYear)
              ? ["Pour toutes les années"]
              : [],
          full_week: [0, 1, 2, 3, 4, 5, 6],
          all_date_disabled: [],
          enable_date: [],
          day_data: guar.day != null ? guar.day : [],
          monthSelected: this.getMonthSelectedUpdate(guar.month, this.months),
          all_month_selected: guar.month.length < 12 ? true : false,
          option: guar.day != null ? "jours" : "date",
          open: true,
          isGetDay: guar.day != null ? true : false,
        });
      });
    },

    /**
     * get all month with data by database to update
     * @param month Array
     * @param months_data Object<Array>
     * @return  datas array
     */
    getMonthSelectedUpdate(months, months_data) {
      var datas = [];
      if (months.length > 0) {
        months_data.forEach((ms) => {
          months.forEach((m) => {
            if (m == ms.id_activite) {
              datas.push(ms);
            }
          });
        });
      }
      return datas;
    },

    /**
     * Generate the now date
     * @return date
     */
    gateNowDate() {
      let today = new Date();
      let date =
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1) +
        "-" +
        today.getDate();
      return date;
    },
    /**
     * Genereate the past date disabled
     * @param date Date
     * @return Boolean
     */
    generateAllowedDates(val) {
      return moment(this.gateNowDate()).isBefore(val);
    },

    /**
     * Get condition all date or all month
     * @param event Event
     * @param index number
     */
    getCondition($event, index) {
      var value = $event;
      this.count++;
      if (value && value.length == 0) {
        this.reverseMonthSelected(index);
        this.applicateForAllMonthDay(index, this.garanties[index].dates, true);
      }
      if (this.garanties[index].dates.length == 0) {
        alert("Veuillez sélectionner une date");
        this.garanties[index].choix = [];
        return;
      }
      for (var i = 0; i < value.length; i++) {
        var condition = value[i];
        switch (condition) {
          case "Pour toutes les années":
            this.garanties[index].isAllYear = true;
            break;
          case "Pour tous les mois":
            if (this.garanties[index].is_day_selected == false) {
              this.applicateForAllMonthDay(
                index,
                this.garanties[index].dates,
                false
              );
            }
            this.isAllMonthSelected(index);
            this.garanties[index].monthSelected = this.months;
            this.garanties[index].isAllmonth = true;
            this.garanties[index].month = [
              1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12,
            ];
            break;
          case null:
            this.reverseMonthSelected(index);
            break;
          default:
            break;
        }
      }
    },
    /**
     * Say if it  is for all Month
     * @param index number
     * @return void
     */
    isAllMonthSelected(index) {
      if (this.garanties[index].month) {
        for (let i = 1; i <= 12; i++) {
          for (
            let j = 1;
            j <= new Date(this.d.getFullYear(), this.d.getMonth(), 0).getDate();
            j++
          ) {
            let item_of_date = new Date(
              `${this.d.getFullYear()}-${i >= 10 ? i : "0" + i}-${
                j >= 10 ? j : "0" + j
              }`
            );
            let date = `${this.d.getFullYear()}-${i >= 10 ? i : "0" + i}-${
              j >= 10 ? j : "0" + j
            }`;

            this.garanties[index].day.forEach((d) => {
              if (item_of_date.getDay() == d.id_activite) {
                if (
                  !this.garanties[index].dates.includes(date) &&
                  moment(date) > moment(this.gateNowDate())
                ) {
                  this.garanties[index].dates.push(date);
                }
              }
            });
          }
        }
      }
    },

    /**
     * Get the data week or months selected
     * @param inde number
     * @return void
     */
    getWeekOrMonth(index) {
      console.log("index", index);
      if (this.garanties[index].day.length > 0) {
        this.garanties[index].all_month_selected = false;
        this.getWeek(index, false, this.allDateForOnYear());
      } else {
        this.garanties[index].dates = [];
      }
      if (this.garanties[index].monthSelected.length <= 0) {
        this.garanties[index].dates = [];
        this.getWeek(index, false, this.allDateForOnYear());
      } else {
        this.garanties[index].all_month_selected = true;
        this.getMountCondition(index).then((data) => {
          this.getWeek(index, true, data);
        });
      }
      this.$forceUpdate();
    },

    /**
     * Get month selected
     * @param index number
     * @return new Promise()
     */
    getMountCondition(index) {
      this.allMonthSelected(index, this.garanties[index].monthSelected).forEach(
        (d) => {
          if (moment(d) > moment(this.gateNowDate())) {
            if (!this.garanties[index].dates) {
              this.garanties[index].dates.push(d);
            }
          }
        }
      );
      return new Promise((reslove, reject) => {
        reslove(
          this.allMonthSelected(index, this.garanties[index].monthSelected)
        );
      });
    },

    /**
     * Generate the item of all date for one year
     * @return datas Array
     */
    allDateForOnYear() {
      var datas = [];
      for (let i = 1; i <= 12; i++) {
        for (
          let j = 1;
          j <= new Date(this.d.getFullYear(), this.d.getMonth(), 0).getDate();
          j++
        ) {
          datas.push(
            `${this.d.getFullYear()}-${i >= 10 ? i : "0" + i}-${
              j >= 10 ? j : "0" + j
            }`
          );
        }
      }

      return datas;
    },

    /**
     * Toggle for all month selected if it is day
     * @param index number
     * @param dates Date<Array>
     * @return  void
     */
    applicateForAllMonthDay(index, dates, is_reverse) {
      var count_number_month_selected = 0;
      var datas = [];
      if (is_reverse) {
        //  reverse if is deselected
        count_number_month_selected = Math.ceil(
          this.garanties[index].dates.length / 12
        );
        console.log(count_number_month_selected);
        for (let i = 0; i < count_number_month_selected; i++) {
          if (
            new Date(this.garanties[index].dates[0]).getMonth() ==
            new Date(this.garanties[index].dates[i]).getMonth()
          ) {
            datas.push(this.garanties[index].dates[i]);
          }
        }
        this.garanties[index].dates = datas;
      } else {
        // add all date for all month selected
        this.allDateForOnYear().forEach((date) => {
          dates.forEach((date_garantie) => {
            if (new Date(date_garantie).getDate() == new Date(date).getDate()) {
              if (!this.garanties[index].dates.includes(date)) {
                this.garanties[index].dates.push(date);
              }
            }
          });
        });
      }
    },

    /**
     * Delete one garantie data
     * @param index  number
     * @return void
     */
    deleteGaraties(index) {
      if (this.garanties[index].id) {
        if (confirm("Voulez-vous supprimer?") == true) {
          Axios.delete(this.base_url_back + "circuit/deleteSaison", {
            data: {
              id: this.garanties[index].id,
              guarted_departure: true,
            },
          }).then((response) => {
            console.log(response);
            if (response.data.success) {
              this.garanties.splice(index, 1);
              this.showDay = false;
              this.showDate = false;
              alert("suppression avec success");
              this.refreshGarantie(this.garanties);
            } else {
              alert("suppression non success");
            }
          });
        }
      } else {
        this.garanties.splice(index, 1);
        this.showDay = false;
        this.showDate = false;
      }
    },
    async refreshGarantie(garantie) {
      // this.guarted_data = garantie
      this.getGarantieData(garantie);
      this.saison_data = [];
      await garantie.forEach((element, i) => {
        this.saison_data.push({
          id: element.id,
          label: "Date " + Number(i + 1),
        });
      });
      await this.getSeason(this.dataCircuit);
    },

    onAddGaraties() {
      if (this.garanties[this.garanties.length - 1] != "") {
        this.tabg = this.garanties.length;
        this.garanties.push({
          day: [],
          dates: [],
          month: [new Date().getMonth() + 1],
          isAllWeek: false,
          isAllYear: false,
          isAllDay: false,
          isAllmonth: false,
          choix: [],
          full_week: [0, 1, 2, 3, 4, 5, 6],
          all_date_disabled: [],
          enable_date: [],
          day_data: [],
          isGetDay: false,
          open: false,
          monthSelected: [],
          all_month_selected: false,
        });
      }
    },
    /**
     * get the option
     * @param op any
     * @param index number
     */
    getOption(op, index) {
      this.garanties[index].open = true;
      if (op == "date") {
        // if the options is  date
        this.garanties[index].isGetDay = false;
        this.garanties[index].is_day_selected = false;
        // this.garanties[index].all_month_selected = true
        this.isGetDay = false;
        this.getDateValue(index);
      } else {
        // if the options is day
        this.garanties[index].monthSelected = [];
        this.garanties[index].isGetDay = true;
        this.garanties[index].is_day_selected = true;
        this.isGetDay = true;
        this.getWeek(index);
      }
    },
    /**
     * get the value of this date
     * @param index number
     * @return void
     */
    getDateValue(index) {
      if (this.garanties[index].dates != "") {
        // this.garanties[index].day = null;
        this.garanties[index].choix = "";
      }
    },
    /**
     * Get all week selected
     * @param index number
     * @param isAllMonth Bolean
     * @param datas Array
     * @return void
     */
    getWeek(index, isAllMonth = false, datas = []) {
      var dates = [];
      // generate all utils dates
      datas.forEach((data) => {
        this.garanties[index].day.forEach((d) => {
          if (new Date(data).getDay() == d.id_activite) {
            if (
              isAllMonth == false
                ? this.d.getMonth() == new Date(data).getMonth()
                : true
            ) {
              if (!dates.includes(data)) {
                dates.push(data);
              }
            }
          }
        });
      });
      dates.forEach((d) => {
        if (moment(d) > moment(this.gateNowDate())) {
          if (!this.garanties[index].dates.includes(d)) {
            this.garanties[index].dates.push(d);
          }
        }
      });
    },
    // Methods for tags
    getAllTags() {
      Axios.get(this.base_url + "getAllTags")
        .then((response) => {
          response = response.data;
          response.forEach((item) => {
            this.tags.push({ value: item.id, text: item.tag_name });
            // if (item.suggestion) {
            // }

            this.autocompleteTags.push(item.tag_name);
          });
        })
        .catch((err) => {
          console.log("Error tags", err);
        });
    },
    getAllTypes() {
      Axios.get(this.base_url + "getAllCircuitType")
        .then((response) => {
          response.data.forEach((item) => {
            this.types.push({ value: item.id, text: item.titre_thematique });
          });

          this.types.shift();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    set_tags() {
      this.tags_circuit.forEach((item) => {
        if (item.tag) {
          this.taglist.push(item.tag.id);
        }
      });
    },

    saveOrUpdateTypeTag() {
      this.loading_type = true;
      this.button_name_type = null;
      Axios.post(this.base_url_back + "circuit/updateTypetag", {
        circuit_id: this.copy.circuit_id,
        types: this.categorys_seo,
        tags: this.taglist,
        taginputs: this.inputtags,
        circuit_copy_id: this.copy.id,
        model_circuit: this.model_circuit,
      })
        .then((response) => {
          console.log(response);
          this.loading_type = false;
          this.button_name_type = "Enregistrer et publier";
          this.$toaster.success("Mots clés ajoutés avec succès");
        })
        .catch((err) => {
          console.log(err);
          this.$toaster.error(
            "Une erreur est survenu lors de votre enregistrement"
          );
        });
    },
    // Methods for the best point
    addStreng(index) {
      // add moment fort
      this.inputs.push("");
    },
    removeStreng(index) {
      // delete moment fort
      this.inputs.splice(index, 1);
      this.stregths.splice(index, 1);
    },

    saveOrUpdateStregth() {
      this.loading_stregth = true;
      this.button_name_stregth = null;
      Axios.post(this.base_url_back + "circuit/updateStregth", {
        stregths: this.stregths,
        circuit_id: this.copy.circuit_id,
        circuit_copy_id: this.copy.id,
        model_circuit: this.model_circuit,
      })
        .then((response) => {
          console.log(response);
          this.loading_stregth = false;
          this.button_name_stregth = "Points forts enregistrés";
          this.$toaster.success("Les points forts sont bien enregistrés");
        })
        .catch((err) => {
          console.log(err);
          this.$toaster.error(
            "Une erreur est survenu lors de votre enregistrement"
          );
        });
    },

    add_prestation_value() {
      if (this.prestation_value === "") {
        return;
      }
      this.option_prestations_incluse.push(this.prestation_value);
      this.model_prestations_incluse.push(this.prestation_value);
      this.prestation_value = "";
    },

    add_prestation_non_inclu_value() {
      if (this.prestation_value_non_inclus === "") {
        return;
      }

      this.option_prestations_non_inclse.push(this.prestation_value_non_inclus);
      this.model_prestations_non_incluse.push(this.prestation_value_non_inclus);
      this.prestation_value_non_inclus = "";
    },

    /**
     * Add moment fort
     * @param index
     * @return Void
     */
    add(index) {
      this.inputs.push({ name: "" });
    },

    /**
     * Remove moment fort
     * @param index
     * @return Void
     */
    remove(index) {
      this.inputs.splice(index, 1);
      this.stregths.splice(index, 1);
    },

    updateOrSavePrestation() {
      this.loading_provider = true;
      this.button_name_provider = "Modifier ces prestations";
      Axios.post(this.base_url_back + "circuit/updatePrestation", {
        data_on: this.model_prestations_incluse,
        data_off: this.model_prestations_non_incluse,
        circuit_id: this.copy.circuit_id,
        circuit_copy_id: this.copy.id,
        prestation_detail: this.description_prestations,
        model_circuit: this.model_circuit,
      })
        .then((response) => {
          console.log(response);
          this.loading_provider = false;
          this.button_name_provider = "Modifier ces prestations";
          this.$toaster.success("Les prestations sont bien enregistrées");
        })
        .catch((err) => {
          console.log(err);
          day.loading = false;
          this.$toaster.error(
            "Une erreur est survenu lors de votre enregistrement"
          );
        });
    },
    // Method for price
    // updateOrSavePrice() {
    //   this.loading_price = true;
    //   if (this.guarted_departure && this.garantie_prix === "") {
    //     this.$toaster.error("Prix Obligatoire");
    //     return false;
    //   }

    //   this.button_name_price = null;
    //   this.all_nb_voyage[this.selected_index_vg] = this.price_etoiles;
    //   const data_price = this.guarted_departure
    //     ? {
    //       circuit_copy_id: this.circuit_copy_id,
    //       circuit_id: this.circuit_id,
    //       value: this.garantie_prix,
    //     }
    //     : {
    //       circuit_id: this.circuit_id,
    //       price: this.price_etoiles,
    //       tranche: this.nb_voyageurs[this.selected_index_vg],
    //       circuit_copy_id: this.circuit_copy_id,
    //     };
    //   Axios.post(this.base_url_back + "circuit/updatePrice", data_price)
    //     .then((response) => {
    //       this.loading_price = false;
    //       this.$toaster.success("Les prix  sont bien enregistrés");
    //       this.price_etoiles.save = true;
    //       this.button_name_price = "Modifier ce prix";
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //       this.$toaster.error(
    //         "Une erreur s'est produit lors du traitement de votre demande"
    //       );
    //     });
    // },
    onUpdateOrSavePriceSaison() {
      let model_circuit = this.model_circuit;
      let saison_type = "App\\Saison";
      let circuit_id = "";
      let type_table = "";
      console.log(model_circuit);
      if (!this.guarted_departure && this.cat_prix.length == 1) {
        type_table = "simple_price";
      } else if (!this.guarted_departure && this.cat_prix.length > 1) {
        type_table = "mixed_price";
      } else if (this.guarted_departure) {
        type_table = "garanted";
        saison_type = "App\\DepartGarantie";
      }

      this.saison_price_data.forEach((element) => {
        element.tranche_value = this.nb_voyageurs[element.tranche];
        element.saison_id_data = [];
        if (element.saison_id.length > 0) {
          element.saison_id.forEach((el) => {
            element.saison_id_data.push(el.id);
          });
        }
      });

      if (this.circuit_copy_id) {
        circuit_id = this.circuit_copy_id;
        // model_circuit = "App\\CircuitCopy"
      } else {
        circuit_id = this.circuit_id;
        // model_circuit = "App\\Circuits"
      }

      Axios.post(this.base_url_back + "SaveOrUpdate/season_price", {
        data: this.saison_price_data,
        circuit_id: circuit_id,
        model_circuit: model_circuit,
        type_table: type_table,
        saison_type: saison_type,
      })
        .then((response) => {
          console.log(response);
          if (response.data.state == "success") {
            this.getSeason(response.data.data);
            this.loading_price = false;
            this.$toaster.success("Les prix  sont bien enregistrés");
            this.price_etoiles.save = true;
            this.button_name_price = "Modifier ce prix";
          } else {
            this.$toaster.error(
              "Une erreur s'est produit lors du traitement de votre demande"
            );
          }
        })
        .catch((err) => {
          this.$toaster.error(
            "Une erreur s'est produit lors du traitement de votre demande"
          );
        });
    },
    /**
     *
     * @param array
     * @param key
     * @return {string[]}
     * @constructor
     */
    ArrayGroupBy(array, key) {
      let result = [];
      array.forEach((item) => {
        if (item[key] != "96ed6e41-725b-4fac-9daa-d87b4c2edaba") {
          if (!result[item[key]]) {
            result[item[key]] = [];
          }
          result[item[key]].push(item);
        }
      });

      return Object.keys(result);
    },
    deleteTranchNbVoyage(index) {
      if (confirm("Vous voullez vraiment le supprimer ? ")) {
        this.saison_price_data[index].input_val_tranch = false;
        this.saison_price_data[index].nb_min = null;
        this.saison_price_data[index].nb_max = null;
      }
    },
    reinitialisationPersonalNbVoyage() {
      this.input_val_tranch = false;
      this.nb_max = null;
      this.nb_min = null;
      this.indexEdit = null;
    },
    shangeStateSave(el) {
      el.save = false;
    },
    /**
     *
     * @param nb_min
     * @param nb_max
     */
    addTranchNbVoyage(index_saison, nb_min, nb_max) {
      this.saison_price_data[index_saison].input_val_tranch = false;
      let newNb;
      if (
        (nb_min != null && nb_max != null && nb_min * 1 <= nb_max * 1) ||
        (nb_min != null && nb_max == null) ||
        nb_max == ""
      ) {
        newNb =
          (nb_min * 1 < 10 ? "0" + nb_min * 1 : nb_min) +
          (nb_max != null && nb_max != ""
            ? "-" + (nb_max * 1 < 10 ? "0" + nb_max * 1 : nb_max)
            : "");
        if (this.cherchTbable(newNb) != null) {
          this.selected_index_vg = this.cherchTbable(newNb);
        } else {
          if (this.indexEdit != null) {
            this.nb_voyageurs[this.indexEdit] = newNb;

            this.selected_index_vg = this.indexEdit;
          } else {
            this.nb_voyageurs.push(newNb);
            this.selected_index_vg = this.nb_voyageurs.length - 1;
          }
        }

        this.reinitialisationPersonalNbVoyage();
        // this.onChangeVoyage(index_saison, this.selected_index_vg);
      } else {
        this.$toast.error(
          this.$toasted,
          "Erreur veuillez vérifier votre tranche personnalisé",
          "toasted-primary"
        );
      }
    },
    cherchTbable(str) {
      let index = null;
      for (let i = 0; i < this.nb_voyageurs.length; i++) {
        if (this.nb_voyageurs[i] == str) {
          index = i;
          break;
        }
      }
      return index;
    },
    /**
     *
     * @param index
     */

    onEditNbVoyage(index, i) {
      this.saison_price_data[index].nb_min_props =
        this.nb_voyageurs[i].split("-")[0] * 1;
      this.saison_price_data[index].nb_max_props =
        this.nb_voyageurs[i].split("-")[1] * 1;
      this.saison_price_data[index].input_val_tranch = true;
    },

    // editNbVoyage(index) {
    //   this.indexEdit = index;
    //   this.nb_min = this.nb_voyageurs[index].split("-")[0] * 1;
    //   this.nb_max = this.nb_voyageurs[index].split("-")[1] * 1;
    //   console.log("prix tranch", this.nb_max, " - -", this.nb_min);
    //   this.input_val_tranch = true;
    // },

    deleteNbVoyage(index_saison, index) {
      if (confirm("Vous voullez vraiment le supprimer ? ")) {
        console.log(this.all_nb_voyage[index_saison][index]);
        if (this.all_nb_voyage[index_saison][index]) {
          let data_price = {
            mix_price:
              this.all_nb_voyage[index_saison][index].mixte_price != "" &&
              this.all_nb_voyage[index_saison][index].mixte_price != null
                ? true
                : false,
            id: this.all_nb_voyage[index_saison][index].id,
          };
          Axios.post(this.base_url_back + "circuit/deletePrice", data_price)
            .then((response) => {
              console.log(response);
              this.$toaster.success("Les prix  sont bien supprimé", response);

              this.all_nb_voyage[index_saison].splice(index, 1);
              let keys = Object.keys(this.all_nb_voyage[index_saison]);
              if (keys.length > 0) {
                this.onChangeVoyage(index_saison, keys[0]);
              }
              //
            })
            .catch((err) => {
              console.log(err);
              alert("erreur");
            });
        }
      }
    },
    /**
     *
     * @param index
     */
    onChangeVoyage(index_saison, index) {
      this.button_name_price = "Enregistrer ce prix";

      this.saison_price_data[index_saison].price_etoiles = {
        price: "",
        deux_etoiles: "",
        trois_etoiles: "",
        quatres_etoiles: "",
        cinq_etoiles: "",
        mixte_cat1: this.cat_prix[0] ? this.cat_prix[0] : "",
        mixte_cat2: this.cat_prix[1] ? this.cat_prix[1] : "",
        mixte_cat3: this.cat_prix[2] ? this.cat_prix[2] : "",
        mixte_price: "",
        save: false,
      };
      // this.saveMixePrice = false;

      if (this.all_nb_voyage[index_saison]) {
        if (this.all_nb_voyage[index_saison][index]) {
          this.price_etoiles = this.all_nb_voyage[index_saison][index];
          this.saison_price_data[index_saison].price_etoiles =
            this.price_etoiles;
          this.price_etoiles.save = true;
          // this.saveMixePrice = true;
          this.button_name_price = "Modifier ce prix";
        }
      }
    },

    checkTrancheValide(index_saison) {
      this.nb_voyageursValid = []
      if (this.all_nb_voyage[index_saison]) {
        for (let index = 0; index < this.nb_voyageurs.length; index++) {
          if (this.all_nb_voyage[index_saison][index]) {
            this.nb_voyageursValid.push(true)
          } else {
            this.nb_voyageursValid.push(false)
          }
        }
      }
    },
    /**
     *
     * @param data
     * @return {boolean}
     */
    checkCoverVisite(data) {
      var valeur = true;
      data.parcour.forEach((parcour) => {
        parcour.visites.forEach((visite) => {
          if (visite.visite_cover == "" || visite.visite_cover == undefined) {
            valeur = false;
          }
        });
      });

      return valeur;
    },
    /**
     * Save or update day
     * @param target
     * @return Void
     */
    async updateOrSaveDay(target) {
      let all_day = this.all_day;
      this.isLoading = true;
      all_day[target].imageData = [];
      var day = all_day[target];
      day.parcour.forEach((par, index_par) => {
        par.index = index_par;
        par.visites.forEach((vis, index_vis) => {
          vis.index = index_vis;
        });
      });
      day.index = target;

      if (this.checkCoverVisite(day)) {
        day.msgBtnEnregistrement = "";
        day.loading = true;
        day.index = target;
        day.circuit_id = this.circuit_id;
        day.circuit_copy_id = this.id_circuit;
        day.model_circuit = this.model_circuit;
        // day.id = this.copy.id
        await Axios.post(this.base_url + "circuit/updateDay", { day })
          .then((response) => {
            console.log(response);
            var data = Object.assign({}, response.data.data);
            day.loading = false;
            day.id = data.id;
            day.photo = data.photo;
            day.parcour = data.parcour;
            day.msgBtnEnregistrement = "Modifier cette journée";
            day.host_id = data.host_id;
            day.save = true;
            console.log("info day ",day)
            this.$toaster.success("Informations enregistrées ");
          })
          .catch((err) => {
            console.log(err);
            day.loading = false;
            day.msgBtnEnregistrement = "Modifier cette journée";
            this.$toaster.error(
              "Une erreur est survenu lors de votre enregistrement"
            );
          });
        this.cat_prix = this.ArrayGroupBy(this.all_day, "cat_hebergement");
        this.getCircuit();
      } else {
        this.isLoading = false;
        this.$toaster.error("Veuiller insérer la photo de chaque visite");
      }
    },

    removeItine(day, itn) {
      var parcour = this.all_day[day].parcour[itn];
      if (parcour.id != null && parcour.id != undefined) {
        this.deleteDay(parcour, "parcour", day, itn);
      } else {
        this.all_day[day].parcour.splice(itn, 1);
      }
    },
    /**
     * Remove day
     * @param day
     * @return Void
     */
    removeDay(day) {
      const days = this.all_day[day];
      if (days.id != null && days.id != undefined) {
        this.deleteDay(days, "day", day);
      } else {
        this.all_day.splice(day, 1);
      }
    },
    addDay(index, name) {
      let jr;
      let ps;
      let gc;
      if (this.applique_tous_jour == true) {
        jr = {
          nom_hebergement: this.all_day[0].nom_hebergement,
          cat_hebergement: this.all_day[0].cat_hebergement,
          url: this.all_day[0].url,
          photo: this.all_day[0].photo,
          dataImage: this.all_day[0].dataImage,
          nameCover: this.all_day[0].nameCover,
        };
      } else {
        jr = {
          nom_hebergement: "",
          cat_hebergement: "",
          url: "",
          photo: ["", "", "", ""],
          nameCover: ["", "", "", ""],
          dataImage: ["", "", "", ""],
        };
      }

      if (this.applique_tous_jour_pension == true) {
        ps = {
          petit_dejeuner: this.all_day[0].petit_dejeuner,
          dejeuner: this.all_day[0].dejeuner,
          dinner: this.all_day[0].dinner,
        };
      } else {
        ps = {
          petit_dejeuner: false,
          dejeuner: false,
          dinner: false,
        };
      }

      if (this.applique_tous_jour_guide_chauffeur == true) {
        gc = {
          guide: this.all_day[0].guide,
          chauffeur: this.all_day[0].chauffeur,
        };
      } else {
        gc = {
          guide: false,
          chauffeur: false,
        };
      }

      this.jours = {
        nom_hebergement: jr.nom_hebergement,
        cat_hebergement: jr.cat_hebergement,
        url: jr.url,
        photo: jr.photo,
        dataImage: jr.dataImage,
        nameCover: jr.nameCover,
        petit_dejeuner: ps.petit_dejeuner,
        dejeuner: ps.dejeuner,
        dinner: ps.dinner,
        guide: gc.guide,
        chauffeur: gc.chauffeur,
        activiter: "",
        parcour: [],
        save: false,
        loading: false,
        msgBtnEnregistrement: "Enregistrer cette journée",
        circuit_id: this.id_circuit,
        circuit_copy_id: this.circuit_copy_id,
      };

      (this.parcour = {
        title_itineraire: "",
        departure_city: "",
        arrival_city: "",
        path_length: "",
        travel_time: "",
        visites: [],
      }),
        (this.visites = {
          visite_cover: "",
          description_visite: "",
          visitenameCover: "",
        }),
        this.parcour.visites.push(this.visites);
      this.jours.parcour.push(this.parcour);

      this.all_day.push(this.jours);
    },
    deleteDay(data, type, day = null, itn = null, vs = null) {
      let result = window.confirm(
        type == "parcour"
          ? "Vous-voulez vraiment supprimer ce parcours"
          : "Voulez-vous supprimer cette journée" + " ?"
      );
      if (result) {
        Axios.post(this.base_url + "circuit/deleteDay", { data, type })
          .then((response) => {
            if (type == "day") {
              this.all_day.splice(day, 1);
            }

            if (type == "parcour") {
              this.all_day[day].parcour.splice(itn, 1);
            }

            if (type == "visite") {
              this.all_day[day].parcour[itn].visites.splice(vs, 1);
            }

            // success
            this.$toaster.success("Supression fait avec succès");
          })
          .catch((err) => {
            console.log(err);
            this.$toaster.error(
              "Une erreur est survenu lors de votre supression"
            );
          });
      }
    },
    addItine(index) {
      (this.parcour = {
        title_itineraire: "",
        departure_city: "",
        arrival_city: "",
        path_length: "",
        travel_time: "",
        visites: [],
      }),
        (this.visites = {
          visite_cover: "",
          description_visite: "",
          visitenameCover: "",
        }),
        this.parcour.visites.push(this.visites);
      this.all_day[index].save = false;
      this.all_day[index].parcour.push(this.parcour);
    },
    previewImage: function (day, p) {
      // this.scale = 80;
      this.index_day = day;
      this.index_photo_heberge = p;
      let compressor = this.$refs.compressor.$el;
      compressor.click();
    },
    previewImageParcour: function (day, itn, vs) {
      //this.scaleP =80
      this.index_day = day;
      this.index_intinereur = itn;
      this.index_visite = vs;
      let compressor = this.$refs.compressorParcour.$el;
      compressor.click();
    },
    /**
     * chage the day into state
     * @params  day
     */
    chargeDayCircuit(day) {
      // this.all_day = [];
      const parcour = [
        {
          index: 1,
          title_itineraire: "",
          departure_city: "",
          arrival_city: "",
          path_length: "",
          travel_time: "",
          visites: [
            {
              index: 1,
              photo: "",
              description_visite: "",
              visitenameCover: "",
            },
          ],
        },
      ];
      this.all_day.push({
        id: day.id,
        save: true,
        nom_hebergement: day.host != null ? day.host.host_name : "",
        cat_hebergement: day.host != null ? day.host.standing_id : "",
        url: "www." + "noah" + ".com",
        petit_dejeuner: day.breakfast == 1 ? true : false,
        dejeuner: day.lunch == 1 ? true : false,
        dinner: day.diner == 1 ? true : false,
        photo:
          day.host != null
            ? day.host.hosts_gallerie.map((data) => {
                return data.host_cover;
              })
            : "",

        position: day.position,

        dataImage:
          day.host != null
            ? day.host.hosts_gallerie.map((data) => {
                return this.imgPathHost + data.host_cover;
              })
            : "",
        nameCover: ["", "", "", ""],
        activiter: day.activity.split(",").map((data) => {
          let datas = [];
          datas = {
            name_activite: data,
            id_activite: this.activites.findIndex(
              (i) => i.name_activite === data
            ),
          };
          return datas;
        }),
        parcour: day.itineraires.length > 0 ? day.itineraires : parcour,
        loading: false,
        title_day: day.title_days,
        guide: day.have_guide == 1 ? true : false,
        chauffeur: day.have_driver == 1 ? true : false,
        guide_language: null,
        circuit_id: day.circuit_id,
        circuit_copy_id: day.circuit_copy_id,
        host: day.host,
        iteneraire: day.itineraires,
        msgBtnEnregistrement: "Modifier cette journée",
        host_id: day.host_id,
      });

      var nbGalerry = day.host.hosts_gallerie.length;
      if (nbGalerry < 4) {
        var iteration = 4 - nbGalerry;
        var k = 0;
        while (k < iteration) {
          this.all_day[this.all_day.length - 1].photo.push("");
          this.all_day[this.all_day.length - 1].dataImage.push("");
          k++;
        }
      }
    },
    /**
     *
     * @param day
     * @returns {Number}
     */
    plus_one_day(day) {
      return day + 1;
    },
    async getFilesParcour(obj) {
      let targ =
        "#preview-visit" +
        this.index_visite +
        "_" +
        this.index_intinereur +
        "_" +
        this.index_day;
      if (obj.original.size.split(" ")[0] == 0) {
        this.scaleP = this.scaleP == 90 ? 92 : 90;
      } else {
        this.all_day[this.index_day].parcour[this.index_intinereur].visites[
          this.index_visite
        ].visite_cover = obj.original.base64;
        this.all_day[this.index_day].parcour[this.index_intinereur].visites[
          this.index_visite
        ].visitenameCover = obj.original.name.split(".")[0];
        var img =
          '<img style="width: 100%!important;height: 100%!important;object-fit: cover!important;" class="preview" src="' +
          obj.original.base64 +
          '">';
        $(targ).html(img);
      }
    },
    getDay(days) {
      if (days.length > 0) {
        this.all_day = [];
        days.forEach((day) => {
          this.chargeDayCircuit(day);
        });
      }
    },

    async getAllStadings() {
      console.log("the result of stadings");
      let result = await Axios.get(this.base_url + "getAllStanding");
      if (!result) {
        return false;
      } else {
        result.data.forEach((item) => {
          this.stadings.push({ value: item.id, text: item.standing_name });
        });
      }
    },

    async getFiles(obj) {
      let targ =
        "#previewHote" + this.index_photo_heberge + "_" + this.index_day;
      if (
        obj.original.size.split(" ")[0] == 0 ||
        obj.original.size.split(" ")[0] == "0"
      ) {
        this.scale = this.scale == 90 ? 92 : 90;
      } else {
        this.all_day[this.index_day].photo[this.index_photo_heberge] =
          obj.original.base64;
        this.all_day[this.index_day].dataImage[this.index_photo_heberge] =
          obj.original.base64;
        this.all_day[this.index_day].nameCover[this.index_photo_heberge] =
          obj.original.name.split(".")[0];
        this.original = obj.original;
        var img =
          '<img style="width: 100%!important;height: 100%!important;object-fit: cover!important;" class="preview" src="' +
          obj.original.base64 +
          '">';
        $(targ).html(img);
      }
    },
    getListDate(start, end) {
      let dateArray = [];
      let currentDate = new Date(start);
      stop = new Date(end);

      while (currentDate <= stop) {
        var d = currentDate;
        var month = "" + (d.getMonth() + 1);
        var day = "" + d.getDate();
        var year = d.getFullYear();

        if (month.length < 2) month = "0" + month;
        if (day.length < 2) day = "0" + day;

        dateArray.push([year, month, day].join("-"));
        let tomorrow = new Date(currentDate);
        currentDate = new Date(tomorrow.setDate(tomorrow.getDate() + 1));
      }
      return dateArray;
    },

    mettrejourDateDisabled() {
      this.disabled_dates = [];
      this.saison_date.forEach((element) => {
        if (
          element.value.start != null &&
          element.value.start != undefined &&
          element.value.end != null &&
          element.value.end != undefined
        ) {
          this.disabled_dates = this.disabled_dates.concat(
            this.getListDate(element.value.start, element.value.end)
          );
        }
      });
    },

    getCircuit() {
      this.loadingPage = true;
      this.circuit_copy_id = this.$route.params.circuit;

      Axios.get(this.base_url_back + "receptive/circuit/" + this.id_circuit)
        .then((response) => {
          response = response.data;

          this.loadingPage = false;
          let data = response.data == null ? response.copy : response.data;
          console.log("getCircuit", data);
          this.is_mixed_price = data.is_mixed_price;
          this.guarted_departure = Boolean(data.is_garanted_departure);
          this.dataCircuit = data;
          // if (this.guarted_departure) {
          //   this.garantie_prix =
          //     data.garantie_prix.length > 0 ? data.garantie_prix[0].value : "";
          // }
          if (data.depart_garantie && data.depart_garantie.length > 0) {
            this.getGarantieData(data.depart_garantie);
          }
          this.infos = data.infos;
          // get Data infopratique
          if (this.infos.length > 0) {
            this.infopratiques.contents = [];
            this.infos.forEach((info) => {
              console.log("information pratique", info);
              let data = {
                id: info.id,
                title: info.title,
                contents: info.contents,
              };

              if (!this.infopratiques.contents.includes(data)) {
                this.infopratiques.contents.push(data);
              }
            });
          }
          // end get data infopartique
          // this.copy = data;
          this.saison_date = []
          if (
            data.saisons != null || data.saisons
              ? data.saisons.length !== 0
              : false
          ) {
            data.saisons.forEach((element) => {
              this.saison_date.push({
                value: { start: element.date_start, end: element.date_end },
                id: element.id,
                date_start: element.date_start,
                date_end: element.date_end,
              });
            });
            this.mettrejourDateDisabled();
            //this.loadingPage = false;
          }
          if (response.data != null) {
            //insertion
            this.stateAction = "insert";
            this.circuit_id = data.id;
            this.circuit_copy_id = null;
          } else {
            this.stateAction = "update";
            this.circuit_id = data.circuit_id;
            this.circuit_copy_id = data.id;
          }
          response.copy != null
            ? response.copy.id
            : this.idCircuitDefault != null
            ? this.idCircuitDefault
            : null;
          console.log("data test", data);
          // if (data.days ? data.days.length > 0 : false) {
          //   this.all_day = [];
          this.getDay(data.days.length > 0 ? data.days : []);
          // } else {
          //   this.loadingPage = false;
          //   this.getDay(this.copy != null ? this.copy.days : []);
          // }

          if (data.depart_garantie == 0) {
            data.saisons.forEach((element) => {
              this.saison_data.push({
                id: element.id,
                label: element.date_start + " à " + element.date_end,
              });
            });
          } else {
            data.depart_garantie.forEach((element, index) => {
              this.saison_data.push({
                id: element.id,
                label: "Date " + Number(index + 1),
              });
            });
          }

          if (data.prestations != null) {
            this.loadingPage = false;
            // set prestation
            data.prestations.forEach((pres) => {
              if (this.prestations_circuits) {
                this.prestations_circuits.push(pres);
              }

              if (pres.is_include == 1) {
                if (
                  this.defaultPrestation.inclus.indexOf(pres.item_prestation) !=
                  -1
                ) {
                  this.prestations_incluse.push({
                    text: pres.item_prestation,
                    value: pres.item_prestation,
                  });
                  this.model_prestations_incluse.push(pres.item_prestation);
                } else {
                  this.option_prestations_incluse.push(pres.item_prestation);
                  this.model_prestations_incluse.push(pres.item_prestation);
                  this.inclus.push({ inc: pres.item_prestation });
                  this.incluses_on.push(pres.item_prestation);
                }
              } else {
                if (
                  this.defaultPrestation.ninclus.indexOf(
                    pres.item_prestation
                  ) != -1
                ) {
                  this.prestations_non_incluse.push({
                    text: pres.item_prestation,
                    value: pres.item_prestation,
                  });
                  this.model_prestations_non_incluse.push(pres.item_prestation);
                } else {
                  this.option_prestations_non_inclse.push(pres.item_prestation);
                  this.model_prestations_non_incluse.push(pres.item_prestation);
                  this.nInclus.push({ ninc: pres.item_prestation });
                  this.incluses_off.push(pres.item_prestation);
                }
              }
              // --------------------------------------------------------------
            });
          }

          this.getSeason(data);
          if (data.prestation_details) {
            data.prestation_details.forEach((item) => {
              this.description_prestation.push(item.description);
            });
            this.loadingPage = false;
          }

          if (data.stregths != null) {
            data.stregths.forEach((str) => {
              this.stregths.push(str.item_stregth);
            });
            this.inputs =
              this.stregths.length != 0 ? this.stregths : this.inputs;
            this.loadingPage = false;
          }

          if (data.asc_type_circuits != null) {
            data.asc_type_circuits.forEach((str) => {
              this.categorys_seo.push(str.thematique_id);
            });
            this.loadingPage = false;
          }

          if (data.asc_tag_circuits != null) {
            this.tags_circuit = data.asc_tag_circuits;
            data.asc_tag_circuits.forEach((element) => {
              if (element.tag ? element.tag.suggestion : false) {
                this.taglist.push(element.tag_id);
              } else {
                this.inputtags.push(element.tag ? element.tag.tag_name : "");
              }
            });
            this.loadingPage = false;
          }

          this.cat_prix = this.ArrayGroupBy(this.all_day, "cat_hebergement");
          // this.onChangeVoyage(0, this.);
          this.loadingPage = false;
        })
        .catch((err) => {
          this.loadingPage = false;
          console.log(err);
        });

      this.generateToBoolean();
    },
    getSeason(data) {
      if (data.season_price) {
        this.all_nb_voyage = [];
        this.saison_price_data = [];

        data.season_price.forEach((season_price, i) => {
          console.log(i);
          this.all_nb_voyage[i] = [];
          let saison_data_id = season_price.saison_data_id;
          let saison_id = [];

          if (season_price.simple_price != null && !data.is_mixed_price) {
            season_price.simple_price.forEach((price) => {
              this.nb_voyageurs.indexOf(price.tranche) < 0
                ? this.nb_voyageurs.push(price.tranche)
                : "";

              this.selected_index_vg = this.nb_voyageurs.indexOf(price.tranche);
              price.tranche = this.selected_index_vg;
              this.price_etoiles = this.all_nb_voyage[i][
                this.selected_index_vg
              ] = {
                id: price.id,
                price: price.chez_les_habitants,
                deux_etoiles: price.deux_etoile,
                trois_etoiles: price.trois_etoile,
                quatres_etoiles: price.quatre_etoile,
                cinq_etoiles: price.cinq_etoile,
                mixte_cat1: "",
                mixte_cat2: "",
                mixte_cat3: "",
                mixte_price: "",
              };
            });
          }

          if (season_price.mixed_price != null && data.is_mixed_price) {
            this.loadingPage = false;
            season_price.mixed_price.forEach((price) => {
              this.nb_voyageurs.indexOf(price.tranche) < 0
                ? this.nb_voyageurs.push(price.tranche)
                : "";
              this.selected_index_vg = this.nb_voyageurs.indexOf(price.tranche);
              price.tranche = this.selected_index_vg;
              this.price_etoiles = this.all_nb_voyage[i][
                this.selected_index_vg
              ] = {
                id: price.id,
                price: "",
                deux_etoiles: "",
                trois_etoiles: "",
                quatres_etoiles: "",
                cinq_etoiles: "",
                mixte_cat1: price.categorie_un,
                mixte_cat2: price.categorie_deux,
                mixte_cat3: price.categorie_trois,
                mixte_price: price.price,
              };
            });
          }
          if (
            season_price.mixed_price.length > 0 ||
            season_price.simple_price.length > 0
          ) {
            if (saison_data_id && saison_data_id.length > 0) {
              saison_id = [];

              saison_data_id.forEach((el, j) => {
                let indexSaisonData = this.saison_data.findIndex(
                  (els) => els.id === el.id
                );
                if (indexSaisonData != -1) {
                  saison_id.push({
                    id: this.saison_data[indexSaisonData].id,
                    label: this.saison_data[indexSaisonData].label,
                  });
                }
              });
            }

            this.saison_price_data.push({
              title: season_price.title,
              id: season_price.id,
              input_val_tranch: false,
              tranche: this.selected_index_vg,
              price_etoiles: this.price_etoiles,
              saison_id: saison_id,
            });
          }

          if (season_price.garantie_prix.length > 0) {
            saison_id = [];
            saison_data_id.forEach((el, j) => {
              let indexSaisonData = this.saison_data.findIndex(
                (els) => els.id === el.id
              );
              if (indexSaisonData != -1) {
                saison_id.push({
                  id: this.saison_data[indexSaisonData].id,
                  label: this.saison_data[indexSaisonData].label,
                });
              }
            });

            this.saison_price_data.push({
              title: season_price.title,
              id: season_price.id,
              garantie_prix: season_price.garantie_prix[0].value,
              saison_id: saison_id,
              garantie_prix_id: season_price.garantie_prix[0].id,
            });
          }

          console.log(
            "this.saison_price_data",
            this.saison_price_data,
            this.all_nb_voyage[i]
          );
        });
        this.checkTrancheValide(0)
      }
    },
    generateToBoolean() {
      // typing cast in boolean
      this.all_day.forEach((element, index) => {
        element.dejeuner = Boolean(element.dejeuner);
        element.dinner = Boolean(element.dinner);
        element.guide = Boolean(element.guide);
        element.petit_dejeuner = Boolean(element.petit_dejeuner);
        element.chauffeur = Boolean(element.chauffeur);
      });
    },

    // All method for gallery
    get_all_mage() {
      if (this.copy.id) {
        Axios.get(
          `${this.base_url_back}receptive/circuit-galleries/${this.copy.id}`
        )
          .then((result) => {
            console.log("result", result);
            this.all_images = result.data;
            this.all_images.forEach((element) => {
              if (element.is_slide === 1) {
                if (!this.galleries.includes(element.id)) {
                  this.galleries.push(element.id);
                }
              }
            });
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    send_all_data() {
      if (this.galleries.length > 0) {
        Axios.post(
          `${this.base_url_back}receptive/circuit-slides-galleries/${this.copy.id}`,
          {
            galleries: this.galleries,
          }
        )
          .then((res) => {
            console.log(res);
            this.$toaster.success("Informations enregistrées  :)");
          })
          .catch((error) => {
            console.log("Error", error);
            this.$toaster.error("Une erreur est survenue");
          });
      } else {
        this.$toaster.error("Veuillez sélectionner une image");
      }
    },
    onAddVisite(i_day, i_parcour) {
      let visites = {
        visite_cover: "",
        description_visite: "",
        visitenameCover: "",
      };

      this.all_day[i_day].parcour[i_parcour].visites.push(visites);
    },
    onRemoveVisite(day, itn, vs) {
      var visite = this.all_day[day].parcour[itn].visites[vs];
      if (visite.id != null && visite.id != undefined) {
        this.deleteDay(visite, "visite", day, itn, vs);
      } else {
        this.all_day[day].parcour[itn].visites.splice(vs, 1);
      }
    },
    change_depart(id) {
      let inputs = document.querySelectorAll("#" + id);

      let a = inputs[0];
      let b = inputs[1].value.split(",");
      this.all_day[parseInt(b[0], 10)].parcour[
        parseInt(b[1], 10)
      ].departure_city = a.value;
    },
    change_arrival(id) {
      let inputs = document.querySelectorAll("#" + id);
      let a = inputs[0];
      let b = inputs[1].value.split(",");
      this.all_day[parseInt(b[0], 10)].parcour[
        parseInt(b[1], 10)
      ].arrival_city = a.value;
    },
    getAddressData: function (addressData, placeResultData, id) {
      let value = document.querySelectorAll("#" + id)[1].value.split(",");
      this.all_day[parseInt(value[0], 10)].parcour[
        parseInt(value[1], 10)
      ].departure_city = addressData.locality + "," + addressData.country;
      this.address = addressData;
    },

    getAddress2Data(addressData, placeResultData, id) {
      var value = [];
      if (document.querySelectorAll("#" + id).length > 0) {
        value = document.querySelectorAll("#" + id)[1].value.split(",");
        this.all_day[parseInt(value[0], 10)].parcour[
          parseInt(value[1], 10)
        ].arrival_city = addressData.locality + "," + addressData.country;
      }
    },
  },
  async mounted() {
    await this.getAllStadings();
    await this.getAllTypes();
    await this.getAllTags();
    await this.set_tags();
    await this.getCircuit();
    await this.generateToBoolean();
    await this.get_all_mage();
  },
};
</script>
