var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c('h3',{staticClass:"text-center"},[_vm._v("Messages en Archive")]),_vm._v(" "),_c('div',{staticClass:"messaging"},[_c('div',{staticClass:"inbox_msg"},[_c('div',{staticClass:"inbox_people"},[_vm._m(0),_vm._v(" "),_c('div',{staticClass:"tab-content"},[_c('div',{staticClass:"tab-pane fade show active",attrs:{"id":"archive","role":"tabpanel","aria-labelledby":"archive-tab"}},[_c('div',{staticClass:"inbox_chat"},_vm._l((_vm.listDiscussionA),function(listDiscussion){return _c('div',{key:listDiscussion.id,staticClass:"chat_list active_chat"},[_c('div',{staticClass:"chat_people",on:{"click":function($event){return _vm.getDiscussionArchive(listDiscussion)}}},[_vm._m(1,true),_vm._v(" "),_c('div',{staticClass:"chat_ib"},[_c('h4',[_vm._v("\n                      "+_vm._s(listDiscussion.client != null
                          ? listDiscussion.client.nom +
                            " " +
                            listDiscussion.client.prenom
                          : listDiscussion.customers.nom +
                            " " +
                            listDiscussion.customers.prenom)+"\n                    ")]),_vm._v(" "),_c('h5',[_vm._v("\n                      "+_vm._s(listDiscussion.discussion[0].message)+" "),_c('br'),_c('br'),_vm._v("\n                        "),_c('span',{staticClass:"chat_date"},[_vm._v("\n                        "+_vm._s(_vm._f("dateFormat")(new Date(listDiscussion.discussion[0].created_at),"DD MMMM YYYY  hh:mm")))])])])])])}),0)]),_vm._v(" "),_c('div',{staticClass:"tab-pane fade",attrs:{"id":"convesationNow","role":"tabpanel","aria-labelledby":"convesationNow-tab"}})])]),_vm._v(" "),(_vm.discussionarchive.length != 0)?_c('div',{staticClass:"mesgs"},[_c('div',{staticClass:"msg_history"},[_c('div',{staticClass:"card-body height3"},_vm._l((_vm.discussionarchive),function(discussionarch,dp){return _c('div',{key:dp},[_c('ul',{staticClass:"chat-list"},[_c('li',{class:{
                    in: discussionarch.sender_id != _vm.admin_id,
                    out: discussionarch.sender_id == _vm.admin_id,
                  }},[_vm._m(2,true),_vm._v(" "),_c('div',{staticClass:"chat-body"},[_c('div',{staticClass:"chat-message"},[_c('h5',[_vm._v(_vm._s(discussionarch.message))]),_vm._v(" "),_vm._l((discussionarch.discussion_pieces_joint),function(f){return _c('p',{key:f.id},[_c('a',{attrs:{"href":_vm.baseUrl + _vm.imgPathPiece + f.file,"target":"_black","title":"cliquer pour voir"}},[_c('i',{staticClass:"fa fa-file"}),_vm._v(" vos fichier\n                        ")])])}),_vm._v(" "),_c('p',[_vm._v("\n                        "+_vm._s(_vm._f("dateFormat")(new Date(discussionarch.created_at),"DD MMMM YYYY  h:mm"))+"\n                      ")])],2)])])])])}),0)])]):_vm._e()])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"nav nav-tabs",attrs:{"role":"tablist"}},[_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link active",attrs:{"id":"archive-tab","data-toggle":"tab","href":"#archive","role":"tab","aria-controls":"archive","aria-selected":"true"}},[_vm._v("Archive\n              "),_c('span',{staticClass:"badge badge-custome"})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"chat_img"},[_c('img',{staticClass:"avat",attrs:{"src":"https://bootdey.com/img/Content/avatar/avatar1.png","alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"chat-img"},[_c('img',{attrs:{"alt":"Avtar","src":"https://bootdey.com/img/Content/avatar/avatar1.png"}})])
}]

export { render, staticRenderFns }